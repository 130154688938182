import styled, { css } from 'styled-components'

import { InputBase, IInputBaseProps } from '../InputBase'

interface IInputNumber extends IInputBaseProps {
  $withoutNumberStyle?: boolean;
}

export const InputBaseStyled = styled(InputBase)<IInputNumber>`

${props => props.$withoutNumberStyle
    ? css`
      input[type=number]::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

    input[type=number] {
      -moz-appearance: textfield;
      appearance: textfield;
    }`
    : css``}

`
