import React, { createContext, useCallback, useContext } from 'react'

import localStorageKeys from 'commons/constants/localStorageKeys'
import { login, ILoginData, me, logout as logoutApi } from 'services/jarvisApi/auth'

interface IAuthContext {
  authenticate(data: ILoginData): void;
  isAuthenticated(): boolean;
  logout(): Promise<void>;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext)

export const AuthProvider: React.FC = ({ children }) => {
  const authenticate = useCallback(async (loginData: ILoginData) => {
    const { data } = await login(loginData)
    await localStorage.setItem(localStorageKeys.JARVIS_TOKEN_KEY, data.token)
    await me().then(({ data }) => {
      localStorage.setItem(localStorageKeys.JARVIS_ORGANIZATION_KEY, data.organizations[0].id)
    })
  }, [])

  const isAuthenticated = useCallback(() => {
    return !!localStorage.getItem(localStorageKeys.JARVIS_TOKEN_KEY)
  }, [])

  const logout = useCallback(async () => {
    return logoutApi().then(async () => {
      await localStorage.removeItem(localStorageKeys.JARVIS_TOKEN_KEY)
      await localStorage.removeItem(localStorageKeys.JARVIS_ORGANIZATION_KEY)
    })
  }, [])

  return (
    <AuthContext.Provider value={{ authenticate, isAuthenticated, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

export function useAuth (): IAuthContext {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }

  return context
}
