import React, { createContext, useCallback, useContext } from 'react'

import { useAuth } from 'hooks/auth'
import { useUser } from 'hooks/user'

interface IRolesContext {
  hasRoles(
    roles: string[] | [null],
    defaultPermission?: boolean
    ): boolean;
}

const RolesContext = createContext({} as IRolesContext)

export const RolesProvider: React.FC = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const { user } = useUser()

  const hasRoles = useCallback((roles: string[], defaultPermission = true) => {
    if (roles.length === 0) {
      return defaultPermission
    }

    return roles.some((role: string) => user.roles?.includes(role))
  }, [isAuthenticated, user])

  return (
    <RolesContext.Provider value={{ hasRoles }}>
      { status === 'loading' ? 'loading...' : children}
    </RolesContext.Provider>
  )
}

export function useRoles (): IRolesContext {
  const context = useContext(RolesContext)

  if (!context) {
    throw new Error('useAuth must be used within an RolesProvider')
  }

  return context
}
