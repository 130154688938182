import 'date-fns'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Button, Grid, Box } from 'components'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from 'components/DialogComponents'
import { Form, Textarea } from 'components/HookForm'

import * as yup from 'yup'

import yupValidationResolver from 'utils/yupValidationResolver'

interface IComplaintCloseProps {
  onSuccess(formObject?: object): Promise<void>
  openDialog: boolean
  handleDialogClose(): any
  isSending: boolean
  data?: any
}

const ComplaintCloseDialog : React.FC<IComplaintCloseProps> = ({ onSuccess, openDialog, handleDialogClose, isSending }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  const validationComplaintOwnerSchema = yup.object().shape({
    owner: yup.string().required()
  })
  const methods = useForm({
    resolver: yupValidationResolver(validationComplaintOwnerSchema)
  })

  const onSubmit = (formValues: object) => {
    handleDialogClose()
    return onSuccess(formValues)
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-complaint-close" style={{ minHeight: 'calc(100% + 24px)', maxHeight: 'none' }}>
      <DialogContent>
        <Form onSubmit={onSubmit} methods={methods}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
              <DialogTitle id="form-dialog-title" style={{ paddingLeft: 0 }}>Inserir responsável</DialogTitle>
              <Box paddingLeft={0} paddingTop={0}>
                <DialogContentText>
                    Insira o responsável.
                </DialogContentText>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Textarea
                    name="owner"
                    label="Responsável"
                    fullWidth
                    minRows={1}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <Box minHeight={25}>
                <DialogActions style={{ justifyContent: 'left' }}>
                  <Button onClick={handleDialogClose} color="primary" disabled={isSending}>
                      Cancelar
                  </Button>
                  <Button type="submit" color="primary" disabled={isSending}>
                      Enviar
                  </Button>
                </DialogActions>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default ComplaintCloseDialog
