import React, { useState } from 'react'
import { FaEye, FaEyeSlash } from 'react-icons/fa'

import { IconButton } from '@material-ui/core'

import { IInputBaseProps, InputBase } from '../InputBase'

const InputPassword: React.FC<IInputBaseProps> = (props) => {
  const [showPassword, setShowPasword] = useState(false)

  const ShowPassword: React.FC = () => (
    <IconButton onClick={() => setShowPasword(!showPassword)} >
      {showPassword ? <FaEyeSlash/> : <FaEye />}
    </IconButton>
  )

  return (
    <InputBase {...props} type={showPassword ? 'text' : 'password'} endAdornment={ShowPassword}/>
  )
}

export default InputPassword
