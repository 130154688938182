/* eslint-disable no-unused-vars */
import React, { useState, useCallback } from 'react'
import { useForm } from 'react-hook-form'
import { FaEnvelope, FaUser, FaKey } from 'react-icons/fa'
import { useQuery } from 'react-query'

import {
  Avatar,
  Box,
  Page,
  Tabs,
  Tab,
  TabPanel,
  Button,
  Container,
  ContainerItem,
  Typography,
  Paper,
  Divider
} from 'components'
import { Form, Input, InputPassword } from 'components/HookForm'

import { useSnackbar } from 'notistack'
import * as yup from 'yup'

import { useUser } from 'hooks/user'
import { getUserbyId, changePasswordUserById, updateUserById } from 'services/jarvisApi/user'
import yupValidationResolver from 'utils/yupValidationResolver'

import { AppBarStyled, FormContainer } from './styles'

const MyAccount: React.FC = () => {
  const { user } = useUser()
  const [value, setValue] = useState(0)
  const [serverErrorsPasswordForm, setServerErrorsPasswordForm] = useState([])
  const [serverErrorsInfoForm, setServerErrorsInfoForm] = useState([])
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const validationSchema = yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    email: yup.string().email().required('Campo obrigatório')
  })

  const validationPasswordSchema = yup.object().shape({
    password: yup.string().required('Campo obrigatório'),
    password_confirmation: yup.string().required('Campo obrigatório')
  })

  const methods = useForm({
    resolver: yupValidationResolver(validationSchema)
  })

  const methodsPasswordForm = useForm({
    resolver: yupValidationResolver(validationPasswordSchema)
  })

  const { data: loggedUser, status } = useQuery('loggedUser',
    () => getUserbyId(user.id).then(({ data }) => {
      methods.reset(data)
      return data
    }),
    { refetchOnWindowFocus: false }
  )

  const a11yProps = (index: any) => ({
    id: `nav-tab-${index}`,
    'aria-controls': `nav-tabpanel-${index}`
  })

  const handleChance = useCallback((event: React.ChangeEvent<{}>, number: number) => {
    setValue(number)
  }, [])

  const onSubmit = useCallback((data: any) => {
    updateUserById(user.id, data)
      .then(({ status, data }) => {
        if (status === 200) {
          return enqueueSnackbar('Alterações salvas com sucesso!', { variant: 'success' })
        }
        throw new Error(`Erro ao atualizar o usuário: ${data}`)
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setServerErrorsPasswordForm(response.data.errors)
          return enqueueSnackbar('Ocorreu um erro de validação, revise os dados digitados!', { variant: 'warning' })
        }
        console.error(response)
        enqueueSnackbar('Ocorreu um erro ao salvar, contate o administrador.', { variant: 'error' })
      })
  }, [])

  const onSubmitPasswordForm = useCallback((data: any) => {
    changePasswordUserById(user.id, data)
      .then(({ status, data }) => {
        if (status === 200) {
          return enqueueSnackbar('Alterações salvas com sucesso!', { variant: 'success' })
        }
        throw new Error(data)
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setServerErrorsPasswordForm(response.data.errors)
          return enqueueSnackbar('Ocorreu um erro de validação, revise os dados digitados!', { variant: 'warning' })
        }
        console.error(response.data)
        enqueueSnackbar('Ocorreu um erro ao salvar, contate o administrador.', { variant: 'error' })
      })
  }, [])

  return (
    <Page title="Minha Conta">
      <Container>
        <ContainerItem xs={4} paper>
          {status === 'success' && (
            <>
              <Box display="flex" justifyContent="center" mb="16px">
                <Avatar
                  alt={loggedUser?.name}
                  style={{
                    height: '140px',
                    width: '140px'
                  }}
                >
                  {String(loggedUser?.name).substring(0, 1).toUpperCase()}
                </Avatar>
              </Box>
              <Typography align="center" variant="h6">
                { loggedUser?.name }
              </Typography>
              <Typography align="center">
                { `${loggedUser?.email}` }
              </Typography>
            </>
          )}
        </ContainerItem>
        <ContainerItem xs={8}>
          <AppBarStyled position="static" color="inherit" square={false}>
            <Tabs value={value} onChange={handleChance} centered indicatorColor="primary">
              <Tab label="Informações Pessoais" {...a11yProps(0)}/>
              <Tab label="Alterar Senha" {...a11yProps(1)}/>
            </Tabs>
          </AppBarStyled>
          <Paper>
            <FormContainer>
              <TabPanel value={value} index={0}>
                <Form id="userForm" methods={methods} onSubmit={onSubmit} serverErrors={serverErrorsInfoForm}>
                  <Input name="name" label="Nome" fullWidth startAdornment={FaUser}/>
                  <Input name="email" label="E-mail" fullWidth startAdornment={FaEnvelope}/>
                  <Button type="submit" color="primary" form="userForm"> Salvar </Button>
                </Form>
              </TabPanel>
              <TabPanel value={value} index={1}>
                <Form id="userPasswordForm" methods={methodsPasswordForm} onSubmit={onSubmitPasswordForm} serverErrors={serverErrorsPasswordForm}>
                  <InputPassword name="password" label="Nova senha" fullWidth startAdornment={FaKey}/>
                  <InputPassword name="password_confirmation" label="Confirme sua senha" fullWidth startAdornment={FaKey}/>
                  <Button type="submit" color="primary" form="userPasswordForm"> Salvar </Button>
                </Form>
              </TabPanel>
            </FormContainer>
          </Paper>
        </ContainerItem>
      </Container>
    </Page>
  )
}

export default MyAccount
