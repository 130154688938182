import styled from 'styled-components'

export const PageStyled = styled.div`
  display: flex;
  padding-top: 10%;
  flex-direction: column;
  align-items: center;

  .MuiTypography-root {
    margin-bottom: 12px;
  }

  .MuiFormControl-root {
    margin-bottom: ${props => props.theme.spacing(3)}px;
  }
`
