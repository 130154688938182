import React from 'react'
import { MdExitToApp } from 'react-icons/md'
import { useHistory } from 'react-router-dom'

import { List, ListItem, ListItemIcon, ListItemText } from 'components'

import sidebarMenu from 'commons/constants/sidebarMenu'
import { useAuth } from 'hooks/auth'
import { useRoles } from 'hooks/roles'

const SidebarMenu: React.FC = () => {
  const { hasRoles } = useRoles()
  const history = useHistory()
  const { logout } = useAuth()
  const handleLogout = () => {
    logout()
      .then(() => history.push('/login'))
  }

  return (
    <List>
      {sidebarMenu.map(({ title, icon: Icon, url, roles }) => {
        if (hasRoles(roles)) {
          return (
            <ListItem button key={title} onClick={() => history.push(url)}>
              <ListItemIcon> <Icon size={24}/> </ListItemIcon>
              <ListItemText primary={title} />
            </ListItem>
          )
        }
        return false
      })}
      <ListItem button key="Sair" onClick={handleLogout}>
        <ListItemIcon> <MdExitToApp size={24}/> </ListItemIcon>
        <ListItemText primary="Sair" />
      </ListItem>
    </List>
  )
}

export default SidebarMenu
