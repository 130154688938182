import 'date-fns'
import React from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardDatePickerProps
} from '@material-ui/pickers'

interface IDatePickerProps extends Omit<KeyboardDatePickerProps, 'onChange' | 'value'> {
  name: string
  label: string
}

const DatePicker: React.FC<IDatePickerProps> = ({ name, label, ...rest }) => {
  const { control } = useFormContext()

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        control={control}
        name={name}
        defaultValue={new Date().toISOString()}
        render={({ value, onChange }) => {
          return (
            <KeyboardDatePicker
              margin="none"
              id={`date-picker-dialog-${name}`}
              label={label}
              format="dd/MM/yyyy"
              value={value}
              KeyboardButtonProps={{ 'aria-label': 'change date' }}
              {...rest}
              onChange={(date: any) => {
                try {
                  const newDate = new Date(date).toISOString()
                  onChange(newDate)
                } catch (e) {}
              }}
              inputVariant="outlined"
            />
          )
        }}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DatePicker
