import { createGlobalStyle } from 'styled-components'

const globalStyle = createGlobalStyle`
  body {
    font-family: Roboto, sans-serif;
    margin: 0;

    /* Retira os estilos de cores do autocomplete do google */
    input:-webkit-autofill {
      box-shadow: 0 0 0 100px white inset;
    }

  }

  @font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v50/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased;
}
`

export default globalStyle
