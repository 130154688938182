import React, { useRef, useCallback } from 'react'
import { MdFormatListBulleted as MdFormatListBulletedIcon, MdAdd as AddIcon, MdDelete as DeleteIcon } from 'react-icons/md'
import { useHistory } from 'react-router-dom'

import { Page, Container, ContainerItem, Table, Fab } from 'components'

import { useTheme } from '@material-ui/core'
import { DateTime } from 'luxon'
import { Column } from 'material-table'
import { useConfirm } from 'material-ui-confirm'

import { getAll, deleteUsersById } from 'services/jarvisApi/usersGlobal'

interface IUserData {
  status: string
  created_at: string
}

const Users: React.FC = () => {
  const assetTableRef = useRef({ onQueryChange: () => {} })
  const confirm = useConfirm()

  const router = useHistory()

  const theme = useTheme()

  const columns: Column<IUserData>[] = [
    { title: 'ID', field: 'id' },
    { title: 'E-mail', field: 'email' },
    { title: 'Nome', field: 'name' },
    { title: 'Data de Criação', render: (row) => DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy HH:mm') }
  ]

  const handleDeleteUser = useCallback((id: string) => {
    confirm({
      title: 'Deseja realmente excluir esse usuário?',
      description: 'Essa ação é irreversível.',
      confirmationText: 'Ok',
      cancellationText: 'Cancelar'
    }).then(() => {
      deleteUsersById(id)
        .then(() => {
          return assetTableRef.current && assetTableRef.current?.onQueryChange()
        })
        .catch(console.error)
    })
  }, [])

  const editBtn = <MdFormatListBulletedIcon size={20} color={theme.palette.primary.main}/>
  const deleteIcon = <DeleteIcon size={20} color={theme.palette.primary.main}/>

  return (
    <Page title="Listagem">
      <Container spacing={3}>
        <ContainerItem paper={false} xs={12}>
          <Table
            tableRef={assetTableRef}
            data={(query) => new Promise((resolve, reject) => {
              getAll(query.page + 1, query.pageSize)
                .then(({ data }) => resolve({
                  data: data.data,
                  page: data.meta.current_page - 1,
                  totalCount: data.meta.total
                }))
                .catch(reject)
            })}
            columns={columns} actions={
              [
                {
                  icon: () => editBtn,
                  tooltip: 'Abrir',
                  onClick: (e, rowData) => router.push(`/users/${rowData.id}`)
                },
                {
                  icon: () => deleteIcon,
                  tooltip: 'Excluir',
                  onClick: (e, rowData) => handleDeleteUser(rowData.id)
                }
              ]}
            options={{ actionsColumnIndex: -1, pageSize: 10 }}
          />
        </ContainerItem>
      </Container>
      <Fab
        color="primary"
        aria-label="add"
        style={{ float: 'right', right: 20, bottom: 20, position: 'fixed' }}
        onClick={() => router.push('/users/create')}
      >
        <AddIcon fontSize="large" />
      </Fab>
    </Page>)
}

export default Users
