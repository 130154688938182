import 'date-fns'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { MdAttachFile as AttachFile, MdFileDownload as DownloadIcon } from 'react-icons/md'

import { Button, Grid, Box, Divider, Typography } from 'components'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from 'components/DialogComponents'
import { Form, Input, Textarea, DatePicker, Dropzone } from 'components/HookForm'

import { Chip } from '@material-ui/core'
import FileSaver from 'file-saver'
import { decode } from 'html-entities'
import flattenDeep from 'lodash/flattenDeep'
import * as yup from 'yup'

import mimeTypes from 'commons/constants/mimeTypes'
import {
  getTrackingAttachmentById,
  storeTrackingComplaintById,
  updateTrackingComplaintById
} from 'services/jarvisApi/complaint'
import yupValidationResolver from 'utils/yupValidationResolver'

interface ITrackingProps {
  onSuccess(): Promise<void>
  openDialog: boolean
  handleDialogClose(): any
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>
  isSending: boolean
  data?: any
}

const TrackingDialog : React.FC<ITrackingProps> = ({ onSuccess, openDialog, handleDialogClose, isSending, data = {}, setIsSending }) => {
  const [open, setOpen] = useState(false)
  const [hasData, setHasData] = useState(false)

  const validationTrackingSchema = yup.object().shape({
    tracking: yup.string().required('Campo obrigatório'),
    created_at: yup.string().required(),
    description: yup.string().required('Campo obrigatório')
  })

  const methods = useForm({
    resolver: yupValidationResolver(validationTrackingSchema)
  })

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  useEffect(() => {
    const keys = Object.keys(data)
    const hasKeys = keys.length > 1
    setHasData(hasKeys)

    if (hasKeys) {
      const decodeData = keys
        .map(key => ({ [key]: decode(data[key], { level: 'all' }) }))
        .reduce((acc, x) => {
          for (const key in x) acc[key] = x[key]
          return acc
        }, {})

      return methods.reset(decodeData)
    }
    methods.reset({})
  }, [data])

  const onSubmit = (formValues: object) => {
    setIsSending(true)
    const form = new FormData()
    Object.entries(formValues).forEach(value => {
      if (typeof (value[1]) !== 'string') {
        flattenDeep(value[1]).filter(Boolean).forEach(file => {
          form.append(`${value[0]}[]`, file as Blob)
        })
        return
      }
      form.append(value[0], value[1])
    })

    const api = data.id
      ? updateTrackingComplaintById(data.complaintId, data.id, form)
      : storeTrackingComplaintById(data.complaintId, form)

    return api
      .then(() => onSuccess())
      .then(() => handleDialogClose())
      .finally(() => setIsSending(false))
  }

  const handleDownloadAttachment = async (id: number, attachmentName: string) => {
    const { data: attachment } = await getTrackingAttachmentById(data.complaint_id, data.id, id)
    return FileSaver.saveAs(attachment.signedUrl, attachmentName)
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-tracking-add" style={{ minHeight: 'calc(100% + 24px)', maxHeight: 'none' }}>
      <DialogContent>
        <Form onSubmit={onSubmit} methods={methods}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
              <DialogTitle id="form-dialog-title" style={{ paddingLeft: 0 }}>{ hasData ? `Tracking #${data.id}` : 'Inserir tracking' }</DialogTitle>
              <Box paddingLeft={0} paddingTop={0}>
                <DialogContentText>
                    Insira sua mensagem abaixo para inserir o tracking na denúncia.
                </DialogContentText>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={8}>
                  <Input name="tracking" label="Tracking" fullWidth/>
                </Grid>
                <Grid item xs={12} md={4}>
                  <DatePicker name="created_at" label="Selecione uma data" />
                </Grid>
                <Grid item xs={12}>
                  <Textarea
                    name="description"
                    label="Digite sua mensagem"
                    fullWidth
                    minRows={10}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Dropzone
                    name='attachments'
                    accept={mimeTypes}
                    multiple
                    filesLimit={10}
                    maxSize={10485760}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <Box minHeight={25}>
                <DialogActions style={{ justifyContent: 'left' }}>
                  <Button onClick={handleDialogClose} color="primary" disabled={isSending}>
                    Cancelar
                  </Button>
                  <Button type="submit" color="primary" disabled={isSending}>
                    Enviar
                  </Button>
                </DialogActions>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Divider variant='fullWidth' />
            </Grid>
            {data.attachments && data.attachments.length && (
              <Grid item xs={11}>
                <Box component="div" flexGrow={1} p={1}>
                  <Typography variant="h6" display="inline">Arquivos anexados</Typography>
                </Box>
                {data.attachments.map((attachment: any) => (
                  <Box mt={1} key={attachment.id}>
                    <Chip
                      variant='outlined'
                      color='primary'
                      label={`${attachment.name} - ${150000 / 1000} Kbytes`}
                      icon={<AttachFile size={22} />}
                      deleteIcon={<DownloadIcon />}
                      onDelete={() => handleDownloadAttachment(attachment.id, attachment.name) }/>
                  </Box>
                ))}
              </Grid>
            )}
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default TrackingDialog
