import 'date-fns'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Button } from 'components'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from 'components/DialogComponents'
import { Form, Textarea } from 'components/HookForm'

import * as yup from 'yup'

import { updateComplaintById } from 'services/jarvisApi/complaint'
import yupValidationResolver from 'utils/yupValidationResolver'

interface IComplaintCloseProps {
  onSuccess(): Promise<void>
  openDialog: boolean
  handleDialogClose(): any
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>
  isSending: boolean
  checkoutData: { complaintId: string, checkoutStatus: string, checkoutMessage?: string | null }
}

const ComplaintCheckoutStatusDialog : React.FC<IComplaintCloseProps> = ({
  onSuccess,
  setIsSending,
  openDialog,
  handleDialogClose,
  isSending,
  checkoutData
}) => {
  const [open, setOpen] = useState(false)

  const validationSchema = yup.object().shape({
    checkout_message: yup.string().required('Campo obrigatório')
  })

  const methods = useForm({
    resolver: yupValidationResolver(validationSchema)
  })

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  useEffect(() => {
    if (checkoutData.checkoutMessage) {
      methods.reset({ checkout_message: checkoutData.checkoutMessage })
    }
  }, [checkoutData.checkoutMessage])

  const onSubmit = (formValues: { checkout_message: string }) => {
    setIsSending(true)
    return updateComplaintById(checkoutData.complaintId, { checkout_status: checkoutData.checkoutStatus, checkout_message: formValues.checkout_message })
      .then(() => handleDialogClose())
      .then(() => onSuccess())
      .finally(() => setIsSending(false))
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-confirm-status" style={{ minHeight: 'calc(100% + 24px)', maxHeight: 'none' }}>
      <Form onSubmit={onSubmit} methods={methods}>
        <DialogTitle id="form-dialog-title">Alteração de procedência</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Explique, no espaço fornecido, a razão da modificação na denúncia para a opção de procedência selecionada.
          </DialogContentText>
          <Textarea
            name="checkout_message"
            label="Digite sua justificativa"
            fullWidth
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" disabled={isSending}>
            Cancelar
          </Button>
          <Button type="submit" color="primary" disabled={isSending}>
            Enviar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ComplaintCheckoutStatusDialog
