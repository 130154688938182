import 'date-fns'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'

import { Button } from 'components'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from 'components/DialogComponents'
import { Form, Textarea } from 'components/HookForm'

import * as yup from 'yup'

import { storeCommentComplaintById } from 'services/jarvisApi/complaint'
import yupValidationResolver from 'utils/yupValidationResolver'

interface IComplaintCloseProps {
  onSuccess(): Promise<void>
  openDialog: boolean
  handleDialogClose(): any
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>
  isSending: boolean
  complaintId: string
}

const ComplaintWaitInformationDialog : React.FC<IComplaintCloseProps> = ({ onSuccess, setIsSending, openDialog, handleDialogClose, isSending, complaintId }) => {
  const [open, setOpen] = useState(false)

  const validationSchema = yup.object().shape({
    message: yup.string().required('Campo obrigatório')
  })

  const methods = useForm({
    resolver: yupValidationResolver(validationSchema)
  })

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  const onSubmit = (formValues: object) => {
    setIsSending(true)
    return storeCommentComplaintById(complaintId, formValues)
      .then(() => handleDialogClose())
      .then(() => onSuccess())
      .finally(() => setIsSending(false))
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-confirm-status" style={{ minHeight: 'calc(100% + 24px)', maxHeight: 'none' }}>
      <Form onSubmit={onSubmit} methods={methods}>
        <DialogTitle id="form-dialog-title">Alteração de Status</DialogTitle>
        <DialogContent>
          <DialogContentText>
                Para alterar a denúncia é preciso enviar uma mensagem ao cliente com mais informações.
          </DialogContentText>
          <Textarea
            name="message"
            label="Digite sua mensagem"
            fullWidth
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary" disabled={isSending}>
            Cancelar
          </Button>
          <Button type="submit" color="primary" disabled={isSending}>
            Enviar
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default ComplaintWaitInformationDialog
