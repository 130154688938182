import { useTheme } from '@material-ui/core/styles'

interface IStatus {
  [key: string]: {
    text: string,
    color: string
  }
}

const useCheckoutStatus = () : IStatus => {
  const theme = useTheme()

  return {
    INADMISSIBLE: { text: 'Improcedente', color: theme.palette.warning.dark },
    INCONCLUSIVE: { text: 'Inconclusiva', color: theme.palette.info.light },
    NOT_CLASSIFIED: { text: 'Não classificado', color: theme.palette.error.dark },
    PARTIALLY_PROCEEDING: { text: 'Parcialmente procedente', color: theme.palette.info.dark },
    ADMISSIBLE: { text: 'Procedente', color: theme.palette.success.dark }
  }
}

export default useCheckoutStatus
