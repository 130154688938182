import React from 'react'

import MuiButton, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button'
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles'
import capitalize from 'lodash/capitalize'

type ButtonProps = {
  color:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'info'
    | 'warning'
    | 'error'
    | 'default'
    | 'inherit'
} & Omit<MuiButtonProps, 'color'>

const useStyles = makeStyles<Theme>(theme =>
  createStyles({
    containedSuccess: {
      backgroundColor: theme.palette.success.main,
      color: theme.palette.success.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.success.dark
      }
    },
    containedError: {
      backgroundColor: theme.palette.error.main,
      color: theme.palette.error.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.dark
      }
    },
    containedWarning: {
      backgroundColor: theme.palette.warning.main,
      color: theme.palette.warning.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.warning.dark
      }
    },
    containedInfo: {
      backgroundColor: theme.palette.info.main,
      color: theme.palette.info.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.info.dark
      }
    }
  })
)

const Button: React.FC<ButtonProps> = ({ children, color, ...props }) => {
  const classes = useStyles()
  const className = classes?.[`contained${capitalize(color)}`]

  const colorProp =
    ['default', 'inherit', 'primary', 'secondary'].indexOf(color) > -1
      ? (color as 'default' | 'inherit' | 'primary' | 'secondary')
      : undefined

  return (
    <MuiButton variant="contained" {...props} color={colorProp} className={className}>
      {children}
    </MuiButton>
  )
}

Button.displayName = 'Button'

export default Button
