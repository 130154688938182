import React from 'react'
import { QueryClientProvider, QueryClient } from 'react-query'

import { CssBaseline } from '@material-ui/core'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ConfirmProvider } from 'material-ui-confirm'
import { SnackbarProvider } from 'notistack'
import { ThemeProvider } from 'styled-components'

import GlobalStyle from './assets/styles/global'
import { rounded } from './assets/themes'
import AppProvider from './hooks/index'
import Routes from './routes'

const App: React.FC = () => {
  const queryClient = new QueryClient()

  return (
    <MuiThemeProvider theme={rounded}>
      <ThemeProvider theme={rounded}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider maxSnack={3}>
            <ConfirmProvider>
              <AppProvider>
                <GlobalStyle />
                <CssBaseline />
                <Routes/>
              </AppProvider>
            </ConfirmProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

export default App
