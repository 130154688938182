import React from 'react'

import { Paper } from 'components'

import { Box } from '@material-ui/core'

interface ITabPanel {
  index: any;
  value: any;
  children: React.ReactNode;
  paper?: boolean;
}

const TabPanel: React.FC<ITabPanel> = ({ children, value, index, paper = false, ...rest }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...rest}
    >
      { paper
        ? (
          <Paper>
            <Box p={3}>
              { children }
            </Box>
          </Paper>
        )
        : children
      }

    </div>
  )
}

export default React.memo(TabPanel)
