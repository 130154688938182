import { IRouteProps } from 'IRoute'

import MyAccount from 'pages/MyAccount'

const route:IRouteProps[] = [
  {
    path: '/my-account',
    component: MyAccount,
    roles: []
  }
]

export default route
