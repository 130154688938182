import { IRouteProps } from 'IRoute'

import Home from '../../pages/Home'

const route:IRouteProps[] = [
  {
    path: '/',
    component: Home,
    roles: []
  }
]

export default route
