import api from './api'

const baseUrl = '/global/user'

export const getAll = (page:number, pageSize:number) => api.get(`${baseUrl}/`, { params: { page, pageSize } })

export const getUsersbyId = (id: string) => api.get(`${baseUrl}/${id}`)

export const storeUsers = (data: any) => api.post(`${baseUrl}/`, data)

export const updateUsersById = (id: string, data: any) => api.put(`${baseUrl}/${id}`, data)

export const deleteUsersById = (id: string) => api.delete(`${baseUrl}/${id}`, {})

export const passwordResetUsersById = (id: string) => api.put(`${baseUrl}/${id}/passwordReset`, {})

export const associateUserOrganizationByUserId = (id: string, data: any) => api.put(`${baseUrl}/${id}/associateUserOrganization`, data)

export const disassociateUserOrganizationByUserId = (id: string) => api.delete(`${baseUrl}/${id}/disassociateUserOrganization`, {})
