import { IRouteProps } from 'IRoute'

import { List, Show } from '../../pages/Screening'
import permissions from 'commons/constants/permissions'

const path = 'screening'

const route:IRouteProps[] = [
  {
    path: `/${path}`,
    component: List,
    roles: [permissions.COMPLAINT_SCREENING]
  },
  {
    path: `/${path}/:id`,
    component: Show,
    roles: [permissions.COMPLAINT_SCREENING]
  }
]

export default route
