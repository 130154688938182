import React from 'react'
import { MdArrowDownward as ArrowDownwardIcon } from 'react-icons/md'

import {
  Avatar,
  Box,
  Card as MuiCard,
  CardContent,
  Grid,
  Typography,
  CardProps
} from '@material-ui/core'
import { lightBlue } from '@material-ui/core/colors'

interface ICardProps extends CardProps {
  label: string
  value: string
  icon?: React.ReactNode
  additionalInfoDisplay?: boolean
  additionalPercent?: string
  additionalInfo?: string
}

const Card: React.FC<ICardProps> = ({
  label,
  value,
  icon,
  additionalInfoDisplay = false,
  additionalPercent = '',
  additionalInfo = '',
  ...props
}) => (
  <MuiCard
    style={{ height: '100%', width: '100%' }}
    {...props}
  >
    <CardContent>
      <Grid
        container
        spacing={3}
        style={{ justifyContent: 'space-between' }}
      >
        <Grid item>
          <Typography
            color="textSecondary"
            gutterBottom
            variant="h6"
          >
            {label}
          </Typography>
          <Typography
            color="textPrimary"
            variant="h3"
          >
            {value}
          </Typography>
        </Grid>
        {icon && <Grid item>
          <Avatar
            style={{
              backgroundColor: lightBlue[600],
              height: 56,
              width: 56
            }}
          >
            {icon}
          </Avatar>
        </Grid>}
      </Grid>
      {additionalInfoDisplay && (
        <Box
          style={{
          // pt: 2,
            display: 'flex',
            alignItems: 'center'
          }}
        >
          <ArrowDownwardIcon style={{ color: lightBlue[900] }} />
          <Typography
            style={{
              color: lightBlue[900]
            }}
            variant="body2"
          >
            {additionalPercent}
          </Typography>
          <Typography
            color="textSecondary"
            variant="caption"
          >
            {additionalInfo}
          </Typography>
        </Box>
      )}
    </CardContent>
  </MuiCard>
)

export default Card
