import React from 'react'

import { Paper } from '@material-ui/core'
import ptBRLocale from 'date-fns/locale/pt-BR'
import MaterialTable, { MaterialTableProps, Localization } from 'material-table'

interface IMaterialTable extends MaterialTableProps<any> {
  elevation?: number
}

const Table: React.FC<IMaterialTable> = ({ options, elevation = 1, ...rest }) => {
  const localization: Localization = {
    body: {
      emptyDataSourceMessage: 'Nenhum registro para exibir',
      dateTimePickerLocalization: ptBRLocale
    },
    pagination: {
      labelRowsPerPage: 'Linhas por pagina',
      firstTooltip: 'Primeira pagina',
      previousAriaLabel: 'Pagina anterior',
      previousTooltip: 'Pagina anterior',
      nextAriaLabel: 'Proxima pagina',
      nextTooltip: 'Proxima pagina',
      lastAriaLabel: 'Ultima pagina',
      lastTooltip: 'Ultima pagina',
      labelRowsSelect: 'linhas',
      labelDisplayedRows: '{from}-{to} de {count}'
    },
    toolbar: {
      searchTooltip: 'Procurar',
      searchPlaceholder: 'Procurar'
    },
    header: {
      actions: 'Ações'
    }
  }

  const componentPaper = (props: any) => <Paper {...props} elevation={elevation} />

  return (
    <MaterialTable
      components={{ Container: componentPaper }}
      localization={localization}
      options={{
        search: false,
        showTitle: false,
        ...options
      }}
      {...rest}
    />
  )
}
export default Table
