import { AxiosRequestConfig } from 'axios'

import api from './api'
export interface ILoginData {
  email: string;
  password: string;
}

export const login = (data:ILoginData) => api.post('/login', data)

export const logout = () => api.delete('/logout', {})

export const me = (config?: AxiosRequestConfig) => api.get('/me', { ...config })
