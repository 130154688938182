import React from 'react'

import {
  Paper,
  Box,
  Typography,
  Grid,
  Status,
  Divider
} from 'components'

import { DateTime } from 'luxon'

const Header: React.FC<any> = ({ data }) => {
  return (
    <Box width={1}>
      <Paper elevation={0}>
        <Box display="flex">
          <Box component="div" flexGrow={1} p={2}>
            <Typography variant="h6" display="inline">Informações Iniciais (#{data.id})</Typography>
          </Box>
          <Box component="div" p={2}>
            <Status backgroundColor={data.status?.color}>
              <Box fontWeight="bold" display="inline">
                {data.status?.text}
              </Box>
            </Status>
          </Box>
        </Box>
        <Divider />
        <Box component="div" display="block" p={2}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2">Nome: {data.name || '-'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Telefone: {data.phone || '-'}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box component="div" display="block" p={2}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2">E-mail: {data.mail || '-'}</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Data Criação: {DateTime.fromISO(data.created_at).toFormat('dd/MM/yyyy HH:mm')} {data.closed_at && `/ Fechado em: ${DateTime.fromISO(data.closed_at).toFormat('dd/MM/yyyy HH:mm')}`}</Typography>
            </Grid>
          </Grid>
        </Box>
        <Box component="div" display="block" p={2}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="body2">Termo: {data.term_accept ? 'Aceito' : 'Não Aceito' }</Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2">Chave de acesso: {data.access_key}</Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Box>
  )
}

export default Header
