import React, { createContext, useContext, useEffect, useState } from 'react'

import { Loading } from 'components'

import { useAuth } from 'hooks/auth'
import { me } from 'services/jarvisApi/auth'

interface IUserContext {
  user: any,
  getMe(): Promise<void>
}

const UserContext = createContext({} as IUserContext)

export const UserProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState({})
  const [busy, setBusy] = useState(true)
  const { isAuthenticated } = useAuth()

  useEffect(() => {
    if (isAuthenticated()) {
      me().then(({ data }) => {
        setUser(data)
        setBusy(false)
      })
    } else {
      setBusy(false)
    }
  }, [])

  const getMe = async () => {
    await me().then(({ data }) => {
      setUser(data)
    })
  }

  return (
    <UserContext.Provider value={{ user, getMe }}>
      { busy ? <Loading /> : children }
    </UserContext.Provider>
  )
}

export function useUser (): IUserContext {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useUser must be used within an UserProvider')
  }

  return context
}
