import React from 'react'
import { Redirect, Route } from 'react-router-dom'

import { IRouteProps } from 'IRoute'

import { useAuth } from 'hooks/auth'
import { useRoles } from 'hooks/roles'

const PrivateRoute: React.FC<IRouteProps> = ({ roles, ...rest }) => {
  const { isAuthenticated } = useAuth()
  const { hasRoles } = useRoles()

  if (!isAuthenticated()) {
    return <Redirect to="/login" />
  }

  if (!hasRoles(roles)) {
    return <Redirect to="/" />
  }

  return <Route {...rest} />
}

export default PrivateRoute
