import React from 'react'

import { AuthProvider } from './auth'
import { OrganizationProvider } from './organizations'
import { RolesProvider } from './roles'
import { UserProvider } from './user'

const AppProvider: React.FC = ({ children }) => (
  <AuthProvider>
    <UserProvider>
      <OrganizationProvider>
        <RolesProvider>
          {children}
        </RolesProvider>
      </OrganizationProvider>
    </UserProvider>
  </AuthProvider>
)

export default AppProvider
