import React from 'react'

import { Slide } from '@material-ui/core'

import { Container, OverPageStyled } from './styles'

interface IOverPage {

}

const OverPage: React.FC<IOverPage> = ({ children }) => (
  <OverPageStyled>
    <Slide in direction="left">
      <Container>
        {children}
      </Container>
    </Slide>
  </OverPageStyled>
)

export default OverPage
