import React, { useEffect, useState, useCallback } from 'react'
import { FaBuilding } from 'react-icons/fa'

import { Avatar, Button, Typography, List, ListItem, ListItemAvatar, ListItemText } from 'components'
import { Dialog, DialogTitle } from 'components/DialogComponents'

import { IOrganizationData } from 'IOrganizations'

import { useAuth } from 'hooks/auth'
import { useOrganization } from 'hooks/organizations'
import { useUser } from 'hooks/user'

import { Container } from './styles'

const SwitchOrganization:React.FC = () => {
  const [open, setOpen] = useState(false)
  const [currentOrganization, setCurrentOrganization] = useState({} as IOrganizationData)

  const { user } = useUser()
  const { isAuthenticated } = useAuth()

  const { changeOrganization, getCurrentOrganization } = useOrganization()

  useEffect(() => {
    const organization = user?.organizations?.find((organization: IOrganizationData) => {
      return organization.id === getCurrentOrganization()
    }) || {} as IOrganizationData

    setCurrentOrganization(organization)
  }, [user, isAuthenticated])

  const handleChangeCompany = useCallback((id) => {
    changeOrganization(id)
  }, [])

  const handleOpenSwitchCompany = useCallback(() => {
    if (user?.organizations?.length > 1) {
      setOpen(true)
    }
  }, [])

  return (
    <Container>
      <Button color="inherit" variant="outlined" onClick={handleOpenSwitchCompany}>
        <Typography variant="body2">
          { currentOrganization.display_name}
        </Typography>
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="simple-dialog-title">
        <DialogTitle> Empresas </DialogTitle>
        <List>
          {user?.organizations?.map((organization: IOrganizationData) => (
            <ListItem button key={organization.id} selected={organization.id === currentOrganization.id} onClick={() => handleChangeCompany(organization.id)}>
              <ListItemAvatar>
                <Avatar color="primary">
                  <FaBuilding />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary={organization.display_name} secondary={organization.document_number}/>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </Container>
  )
}

export default SwitchOrganization
