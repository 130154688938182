import { useCallback } from 'react'

import { BaseSchema } from 'yup'

const yupValidationResolver = (validationSchema: BaseSchema) => useCallback(async (data: object) => {
  try {
    const values = await validationSchema.validate(data, {
      abortEarly: false
    })

    return {
      values,
      errors: {}
    }
  } catch (errors) {
    return {
      values: {},
      errors: errors.inner.reduce((allErrors: any, currentError: any) => ({
        ...allErrors,
        [currentError.path]: {
          type: currentError.type ?? 'validation',
          message: currentError.message
        }
      }), {})
    }
  }
}, [validationSchema])

export default yupValidationResolver
