import { IRouteProps } from 'IRoute'

import permissions from 'commons/constants/permissions'
import { List, CreateOrUpdate } from 'pages/Users'

const route:IRouteProps[] = [
  {
    path: '/users',
    component: List,
    roles: [permissions.USER_ADMIN]
  },
  {
    path: '/users/create/',
    component: CreateOrUpdate,
    roles: [permissions.USER_ADMIN]
  },
  {
    path: '/users/edit/:id',
    component: CreateOrUpdate,
    roles: [permissions.USER_ADMIN]
  },
  {
    path: '/users/:id',
    component: CreateOrUpdate,
    roles: [permissions.USER_ADMIN]
  }
]

export default route
