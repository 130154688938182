import React from 'react'
import { useForm } from 'react-hook-form'

import { Box, Button, Grid } from 'components'
import {
  Dialog, DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from 'components/DialogComponents'
import { Dropzone, Form } from 'components/HookForm'

import flattenDeep from 'lodash/flattenDeep'
import * as yup from 'yup'

import mimeTypes from 'commons/constants/mimeTypes'
import { uploadFinalReport } from 'services/jarvisApi/complaint'
import yupValidationResolver from 'utils/yupValidationResolver'

interface ITrackingProps {
  onSuccess(): Promise<void>
  openDialog: boolean
  handleDialogClose(): any
  setIsSending: React.Dispatch<React.SetStateAction<boolean>>
  isSending: boolean
  data?: any
}

const FinalReportDialog : React.FC<ITrackingProps> = ({ onSuccess, openDialog, handleDialogClose, isSending, data = {}, setIsSending }) => {
  const validationSchema = yup.object().shape({})

  const methods = useForm({
    resolver: yupValidationResolver(validationSchema)
  })

  const onSubmit = (formValues: object) => {
    setIsSending(true)
    const form = new FormData()
    Object.entries(formValues).forEach(value => {
      if (typeof (value[1]) !== 'string') {
        flattenDeep(value[1]).filter(Boolean).forEach(file => {
          form.append(`${value[0]}[]`, file as Blob)
        })
        return
      }
      form.append(value[0], value[1])
    })

    return uploadFinalReport(data.complaintId, form)
      .then(() => onSuccess())
      .then(() => handleDialogClose())
      .finally(() => setIsSending(false))
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" open={openDialog} onClose={handleDialogClose} aria-labelledby="form-dialog-final-report-add" style={{ minHeight: 'calc(100% + 24px)', maxHeight: 'none' }}>
      <DialogContent>
        <Form onSubmit={onSubmit} methods={methods}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
              <DialogTitle id="form-dialog-title" style={{ paddingLeft: 0 }}>Relatório Final</DialogTitle>
              <Box paddingLeft={0} paddingTop={0}>
                <DialogContentText>
                  Faça o upload do relatório final da denúncia.
                </DialogContentText>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Dropzone
                name='attachments'
                accept={mimeTypes}
                multiple
                filesLimit={10}
                maxSize={10485760}
                fullWidth
              />
            </Grid>
            <Grid item xs={11}>
              <Box minHeight={25}>
                <DialogActions style={{ justifyContent: 'left' }}>
                  <Button onClick={handleDialogClose} color="primary" disabled={isSending}>
                    Cancelar
                  </Button>
                  <Button type="submit" color="primary" disabled={isSending}>
                    Enviar
                  </Button>
                </DialogActions>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default FinalReportDialog
