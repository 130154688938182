import React, { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'

import { Page, Grid, Paper, Box, Button } from 'components'
import { Card, PieChart, LineChartComposed, LineChart } from 'components/Dashboard'

import _ from 'lodash'
import get from 'lodash/get'
import { DateTime } from 'luxon'

import useCheckoutStatus from 'hooks/checkoutStatus'
import useStatus from 'hooks/status'
import { summary } from 'services/jarvisApi/complaint'

import { DatePicker, Form } from '../../components/HookForm'

const Home: React.FC = () => {
  const statusHook = useStatus()
  const checkoutStatusHook = useCheckoutStatus()
  const [startDate, setStartDate] = useState(DateTime.local(2020, 1, 1))
  const [endDate, setEndDate] = useState(DateTime.now())

  const { data } = useQuery(['complaint_summary', startDate, endDate],
    () => summary(startDate.toSQLDate(), endDate.toSQLDate()).then(({ data }) => {
      if (Object.keys(data.sla).length) {
        for (const key in data.sla) {
          if (Object.prototype.hasOwnProperty.call(data.sla, key)) {
            const match = key.match(/^(\d{4})(\d{2})$/)
            if (match) {
              const [, year, month] = match
              const value = data.sla[key]
              delete data.sla[key]
              data.sla[`${year}/${month}`] = value
            }
          }
        }
      }

      return data
    }),
    { refetchOnWindowFocus: false, retry: false }
  )

  const methods = useForm()

  const onSubmit = async (formValues: Record<string, string>) => {
    setStartDate(DateTime.fromISO(formValues.start_date))
    setEndDate(DateTime.fromISO(formValues.end_date))
  }

  useEffect(() => {
    methods.reset({ start_date: startDate, end_date: endDate })
  }, [])

  const castValuesByDate = (values: any) => {
    return Object.keys(values).map(key => {
      const name = { name: key }
      const series = _.mapKeys(values[key], function (v, k) {
        if (!statusHook[k]) {
          return k
        }
        return [statusHook[k].text]
      })

      return _.merge(name, series)
    })
  }

  const castValuesByStatus = (values: any) => {
    return Object.keys(values).map(key => {
      return { name: statusHook[key].text, value: Number(values[key]) }
    })
  }

  const castValuesByCheckoutStatus = (values: any) => {
    return Object.keys(values).map(key => {
      return { name: checkoutStatusHook[key].text, value: Number(values[key]) }
    })
  }

  const castValuesByTopics = (data: [{group: string, complaints_count: string}]) => {
    return data.map(value => ({ name: value.group, value: Number(value.complaints_count) }))
  }

  const avgDaysToClose = get(data, 'avg_days_to_close', 0)

  const complaintsAvgClosed = useCallback(() => {
    if (avgDaysToClose !== 0 && avgDaysToClose !== null) {
      return `${avgDaysToClose} ${avgDaysToClose > 1 ? 'dias' : 'dia'}`
    }

    return ' - '
  }, [avgDaysToClose])

  return (
    <Page title="Início">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box display="flex" justifyContent='center' alignItems='center'>
            <Box flexWrap='nowrap'>
              <Paper style={{ padding: '1rem' }}>
                <Form onSubmit={onSubmit} methods={methods}>
                  <Grid container spacing={2} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={6}>
                      <DatePicker name="start_date" label="Selecione uma data inicial" fullWidth maxDate={endDate.toJSDate()} maxDateMessage='A data inicial não pode ser maior que a data final.'/>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DatePicker name="end_date" label="Selecione uma data final" fullWidth maxDate={new Date()} maxDateMessage='A data final não pode ser maior que a data atual.'/>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <Button color='primary' fullWidth type='submit'>Filtrar</Button>
                    </Grid>
                  </Grid>
                </Form>
              </Paper>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Card label="Total de Denúncias" value={get(data, 'complaints_total', 0)} />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Card label="Denúncias Pendentes" value={get(data, 'by_status[\'PENDING\']', 0)} />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Card label="Denúncias em Análise" value={get(data, 'by_status[\'IN_ANALYSIS\']', 0)} />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Card label="Denúncias aguardando mais informações" value={get(data, 'by_status[\'WAITING_FOR_MORE_INFORMATION\']', 0)} />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Card label="Denúncias Concluídas" value={get(data, 'by_status[\'DONE\']', 0)} />
        </Grid>
        <Grid item xs={12} sm={4} lg={2}>
          <Card label="Tempo médio de Apuração" value={complaintsAvgClosed()} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PieChart label="Denúncias por tema" data={castValuesByTopics(get(data, 'complaints_not_reclassified_by_topic', []))} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PieChart label="Denúncias por tema reclassificado" data={castValuesByTopics(get(data, 'complaints_reclassified_by_topic', []))} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PieChart label="Denúncias por status" data={castValuesByStatus(get(data, 'by_status', []))} />
        </Grid>
        <Grid item xs={12} md={6}>
          <PieChart label="Procedência das denúncias" data={castValuesByCheckoutStatus(get(data, 'complaints_by_checkout_status', []))} />
        </Grid>
        <Grid item xs={12}>
          <LineChartComposed
            label="Denúncias por Mês"
            data={castValuesByDate(get(data, 'by_date', []))}
            bars={Object.values(statusHook).map(value => value.text)}
          />
        </Grid>
        <Grid item xs={12}>
          <LineChart
            label="SLA por Mês"
            data={castValuesByDate(get(data, 'sla', []))}
            lines={['SLA']}
          />
        </Grid>
      </Grid>
    </Page>
  )
}

export default Home
