import React from 'react'

import { Paper as MuiPaper, PaperProps } from '@material-ui/core'

const Paper: React.FC<PaperProps> = ({ children, elevation = 1, ...rest }) => (
  <MuiPaper elevation={elevation} {...rest}>
    {children}
  </MuiPaper>
)

export default Paper
