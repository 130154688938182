import React from 'react'

import { LinearProgress } from '@material-ui/core'

import { Container } from './styles'

const Loading: React.FC = () => {
  return (
    <Container>
      <LinearProgress/>
    </Container>
  )
}

export default Loading
