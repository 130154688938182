import Complaint from './complaint'
import Home from './home'
import MyAccount from './myAccount'
// Routes with roles
import Organization from './organization'
import Screening from './screening'
import User from './users'

const routes = [...Complaint, ...MyAccount, ...Home, ...Organization, ...User, ...Screening]

export default routes
