import 'date-fns'
import React, { useState } from 'react'

import DateFnsUtils from '@date-io/date-fns'
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from '@material-ui/pickers'

const DatePicker = (props: any) => {
  const [date, setDate] = useState(null)

  return (
    <>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          margin="normal"
          id="date-picker-dialog"
          label="Selecione uma Data"
          format="dd/MM/yyyy"
          clearable
          value={date}
          onChange={(event: any) => {
            setDate(event)
            props.onFilterChanged(props.columnDef.tableData.id, event)
          }}
          KeyboardButtonProps={{
            'aria-label': 'change date'
          }}
        />
      </MuiPickersUtilsProvider>
    </>
  )
}

DatePicker.displayName = 'CustomDatePicker'

export default DatePicker
