import api from './api'

interface IUser {
  email: string;
  id:number;
  name: string;
  created_at:string;
  updated_at: string
}

interface Meta {
  current_page: number;
  first_page: number;
  first_page_url: string;
  last_page: number;
  last_page_url: string;
  next_page_url: string;
  per_page: number;
  previous_page_url: string;
  total: number;
}

interface PaginatedResponse {
  data: IUser[];
  meta: Meta
}

interface IGetUsersFilterParams {
  only_not_screening_admins: boolean;
}

interface IGetUsersParams {
  page?: number;
  pageSize?: number;
  orderBy?: string;
  orderDirection?: string;
  filters?: IGetUsersFilterParams;
}

export const getUsers = ({ page = 1, pageSize = 50, orderBy, orderDirection, filters }: IGetUsersParams) => api.get<PaginatedResponse>('/users', { params: { page, pageSize, orderBy, orderDirection, filters } })

export const getUserbyId = (id: string) => api.get<IUser>(`/users/${id}`)

export const updateUserById = (id: string, data: any) => api.put<IUser>(`/users/${id}`, data)

export const changePasswordUserById = (id: string, data: any) => api.put(`/users/${id}/changePassword`, data)
