import { Paper } from '@material-ui/core'
import styled from 'styled-components'

export const ContainerStyled = styled.div`
  flex-grow: 1;
`

export const PaperStyled = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(3)}px;
`
