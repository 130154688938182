import React from 'react'

import { IInputBaseProps, InputBase } from '../InputBase'

const Input: React.FC<IInputBaseProps> = (props) => {
  return (
    <InputBase {...props} />
  )
}

export default Input
