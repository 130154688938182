import styled from 'styled-components'

export const OverPageStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${props => props.theme.zIndex.appBar + 1};
`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`
