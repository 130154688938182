import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles'

const theme = unstable_createMuiStrictModeTheme({
  palette: {
    type: 'light',
    primary: { main: '#242D44' },
    secondary: { main: '#6E7A83' },
    background: {
      default: '#EFF0F7'
    }
  },
  overrides: {
    MuiPaper: {
      rounded: { borderRadius: '8px' },
      elevation0: { boxShadow: '0px 32px 64px rgba(17, 17, 17, 0.0)' },
      elevation1: { boxShadow: '0px 32px 64px rgba(17, 17, 17, 0.08)' },
      elevation2: { boxShadow: '0px 32px 64px rgba(17, 17, 17, 0.16)' },
      elevation3: { boxShadow: '0px 32px 64px rgba(17, 17, 17, 0.24)' },
      elevation4: { boxShadow: '0px 32px 64px rgba(17, 17, 17, 0.32)' }
    },
    MuiButton: {
      root: {
        borderRadius: '40px'
      }
    }
  }
})

export default theme
