import React from 'react'

import {
  Paper,
  Box,
  Typography,
  Divider
} from 'components'

import { decode } from 'html-entities'
import { DateTime } from 'luxon'

const QuestionComponent:React.FC<any> = ({ tracking, createdAt, description, userEmail }) => {
  return (
    <>
      <Box component="div" display="block" paddingBottom={1} paddingTop={1} paddingLeft={2} paddingRight={2}>
        <Box width={1} fontWeight="bold" component="span">
          <Typography style={{ fontWeight: 600 }}>({userEmail} - {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy HH:mm')}) - {decode(tracking)}</Typography>
        </Box>
        <Box width={1}component="span">
          <Typography>{decode(description)}</Typography>
        </Box>
      </Box>
      <Divider />
    </>
  )
}

const Header: React.FC<any> = ({ data }) => {
  return (
    <Box width={1}>
      <Paper elevation={0}>
        <Box display="flex">
          <Box component="div" flexGrow={1} p={2}>
            <Typography variant="h6" display="inline">Trackings</Typography>
          </Box>
        </Box>
        <Divider />
        {data?.map(({ tracking, created_at, description, user, id }: any) => <QuestionComponent
          key={id}
          tracking={tracking}
          description={description}
          createdAt={created_at}
          userEmail={user.email}
        />)
        }
      </Paper>
    </Box>
  )
}

export default Header
