import React from 'react'

import {
  Paper,
  Box,
  Typography,
  Divider
} from 'components'

import { decode } from 'html-entities'
import { DateTime } from 'luxon'

const QuestionComponent:React.FC<any> = ({ author, createdAt, message }) => {
  return (
    <>
      <Box component="div" display="block" paddingBottom={1} paddingTop={1} paddingLeft={2} paddingRight={2}>
        <Box width={1} fontWeight="700" component="span">
          <Typography style={{ fontWeight: 600 }}>{author}</Typography>
        </Box>
        <Box width={1} component="span">
          <Typography>{decode(message)}</Typography>
        </Box>
        <Box width={1} mt={1}>
          <Typography variant="body2">{DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy HH:mm')}</Typography>
        </Box>
      </Box>
      <Divider />
    </>
  )
}

const Header: React.FC<any> = ({ data }) => {
  return (
    <Box width={1}>
      <Paper elevation={0}>
        <Box display="flex">
          <Box component="div" flexGrow={1} p={2}>
            <Typography variant="h6" display="inline">Comentários</Typography>
          </Box>
        </Box>
        <Divider />
        {data?.map(({ author, created_at, message, id }: any) => <QuestionComponent
          key={id}
          author={author}
          createdAt={created_at}
          message={message}
        />)
        }
      </Paper>
    </Box>
  )
}

export default Header
