import React from 'react'

import {
  Paper,
  Box,
  Typography,
  Divider
} from 'components'

import { decode } from 'html-entities'

const QuestionComponent:React.FC<any> = ({ question, answer }) => {
  return (
    <>
      <Box component="div" display="block" paddingBottom={1} paddingTop={1} paddingLeft={2} paddingRight={2}>
        <Box width={1} fontWeight="700" component="span">
          <Typography>Q: {decode(question)}</Typography>
        </Box>
        <Box width={1}component="span">
          <Typography>R: {decode(answer)}</Typography>
        </Box>
      </Box>
      <Divider />
    </>
  )
}

const Header: React.FC<any> = ({ data }) => {
  return (
    <Box width={1}>
      <Paper elevation={0}>
        <Box display="flex">
          <Box component="div" flexGrow={1} p={2}>
            <Typography variant="h6" display="inline">Denúncia</Typography>
          </Box>
        </Box>
        <Divider />
        {data?.answers?.map(({ id, question, answer }: any) => <QuestionComponent
          key={id}
          question={question}
          answer={Array.isArray(answer) ? answer.join(', ') : answer}
        />)
        }
      </Paper>
    </Box>
  )
}

export default Header
