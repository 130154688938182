import React, { useState, useCallback } from 'react'
import { FiMenu, FiArrowLeft } from 'react-icons/fi'
import { useHistory } from 'react-router-dom'

import { Divider, Toolbar, Typography } from '@material-ui/core'

import SidebarMenu from './components/SidebarMenu'
import SwitchOrganization from './components/SwitchOrganization'
import {
  AppBarStyled,
  Container,
  IconButtonStyled,
  DrawerStyled,
  DrawerHeader,
  ContainerMain,
  DividerMenu,
  BackToButton
} from './styles'

interface IPage {
  title?: string;
  rightSidebar?: React.ReactNode;
  leftSidebar?: React.ReactNode;
  backTo?: boolean | (() => void) | string ;
}

const Page: React.FC<IPage> = ({ children, title, leftSidebar, rightSidebar, backTo = false }) => {
  const [open, setOpen] = useState(false)
  const history = useHistory()

  const handleDrawerSwitch = () => {
    setOpen(!open)
  }

  const handleBackTo = useCallback(() => {
    if (typeof backTo === 'function') {
      backTo()
    } else if (typeof backTo === 'string') {
      history.push(backTo)
    } else {
      history.goBack()
    }
  }, [])

  return (
    <Container>
      <AppBarStyled position="fixed" open={open} elevation={0}>
        <Toolbar>
          { !backTo && (
            <IconButtonStyled
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerSwitch}
              edge="start">
              <FiMenu color="white"/>
            </IconButtonStyled>
          )}

          {backTo && (
            <BackToButton color="inherit" aria-label="open drawer" onClick={() => handleBackTo()}>
              <FiArrowLeft />
            </BackToButton>
          )}
          <Typography variant="h6" noWrap>{title}</Typography >
          {leftSidebar}
          < DividerMenu />
          {rightSidebar}
          <SwitchOrganization />
        </Toolbar>
      </AppBarStyled>
      <DrawerStyled variant="persistent" anchor="left" open={open}>
        <DrawerHeader/>
        <Divider />
        <SidebarMenu />
        <Divider />
      </DrawerStyled>
      <ContainerMain open={open}>
        <DrawerHeader/>
        {children}
      </ContainerMain>
    </Container>
  )
}

export default Page
