import React from 'react'

import { Box, Card as MuiCard, CardContent, Typography, Grid, Tooltip as MuiTooltip } from 'components'

import { isUndefined } from 'lodash'
import { PieChart as RePieChart, Pie, Cell, ResponsiveContainer, Tooltip, Legend } from 'recharts'

import { ReactComponent as EmptySvg } from 'assets/svg/empty.svg'

interface IPieChart {
  label: string,
  data: {name: string, value: number}[],
  total?: number,
  totalType?: string
  totalTooltip?: string
}

const PieChart: React.FC<IPieChart> = ({ data, label, total, totalType, totalTooltip }) => {
  const COLORS = ['#1abc9c', '#2980b9', '#8e44ad', '#e67e22', '#C2185B', '#009688', '#388E3C', '#00BCD4', '#5D4037', '#FF5722', '#F57C00', '#4CAF50', '#1976D2', '#ED4C67', '#B53471']

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }: any) => {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 1.32
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  const RenderTooltip = ({ title, children }: any) => {
    if (title) {
      return (
        <MuiTooltip title={title} arrow>
          {children}
        </MuiTooltip>
      )
    }
    return children
  }

  const RenderNoData = ({ dataLength, children }: any) => {
    if (!dataLength) {
      return (
        <Box display="flex" justifyContent="center">
          <EmptySvg width="270" height="350" />
        </Box>
      )
    }
    return children
  }

  return (
    <Box>
      <MuiCard>
        <CardContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography component="h5" variant="h5">
                {label}
              </Typography>
            </Grid>
            <RenderNoData dataLength={data.length}>
              {!isUndefined(total) && (
                <Grid item xs={4}>
                  <RenderTooltip title={totalTooltip}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      m="auto"
                      color={`${totalType}.contrastText`}
                      bgcolor={`${totalType}.main`}
                      p={2}
                      borderRadius={16}
                    >
                      <Typography component="h3" variant="h3">
                  0
                      </Typography>
                    </Box>
                  </RenderTooltip>
                </Grid>
              )}
              <Grid item xs={!isUndefined(total) ? 8 : 12}>
                <ResponsiveContainer height={600}>
                  <RePieChart>
                    <Legend layout="horizontal" verticalAlign="top" align="left" />
                    <Pie
                      labelLine={true}
                      label={renderCustomizedLabel}
                      data={data}
                      cx={300}
                      cy={250}
                      innerRadius={10}
                      outerRadius={150}
                      fill="#8884d8"
                      dataKey="value"
                      paddingAngle={5}>
                      {data.map((_entry: any, index:any) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Tooltip />
                  </RePieChart>
                </ResponsiveContainer>
              </Grid>
            </RenderNoData>
          </Grid>
        </CardContent>
      </MuiCard>
    </Box>)
}

export default PieChart
