import { IRouteProps } from 'IRoute'

import permissions from 'commons/constants/permissions'
import MyAccount from 'pages/MyAccount'

const route:IRouteProps[] = [
  {
    path: '/organizations',
    component: MyAccount,
    roles: [permissions.ORGANIZATION_ADMIN]
  }
]

export default route
