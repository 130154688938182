import { AppBar, Drawer, IconButton } from '@material-ui/core'
import styled, { css } from 'styled-components'

interface IAppBarStyled {
  open: boolean;
}

interface IContainerMain {
  open: boolean;
}

const drawerWidth = 240

export const Container = styled.div`
  display: flex;
`
export const AppBarStyled = styled(AppBar)<IAppBarStyled>`
  transition: margin 195ms, width 195ms;

  ${({ open }) => open &&
    css`
      width: calc(100% - ${drawerWidth}px) !important;
      margin-left: ${drawerWidth}px;`
}`

export const DividerMenu = styled.div`
  flex-grow: 1;
`

export const DrawerHeader = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(0, 1)};
  ${({ theme }) => theme.mixins.toolbar}
  justify-content: flex-end;
`

export const DrawerStyled = styled(Drawer)`
  width: ${drawerWidth}px;
  flex-shrink: 0;

  .MuiDrawer-paper {
    width: ${drawerWidth}px;
  }
`

export const ContainerMain = styled.main<IContainerMain>`
  flex-grow: 1;
  padding: ${({ theme }) => theme.spacing(3)}px;
  transition: margin 195ms;

  margin-left: ${({ open }) => open ? 0 : -drawerWidth}px;
`

export const IconButtonStyled = styled(IconButton)`
  margin-right: ${props => props.theme.spacing(1)}px;
`

export const BackToButton = styled(IconButton)`
  margin-right: 0;
`
