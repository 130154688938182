import React, { useEffect, useCallback, useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaBuilding } from 'react-icons/fa'
import { MdAddCircleOutline, MdRemoveCircleOutline, MdLock } from 'react-icons/md'
import { useRouteMatch, useHistory } from 'react-router-dom'

import { Page, OverPage, Paper, Grid, Box, Button, Typography, Table } from 'components'
import { Form, Input } from 'components/HookForm'

import { useSnackbar } from 'notistack'
import * as yup from 'yup'

import { useOrganization } from 'hooks/organizations'
import { useUser } from 'hooks/user'
import { getUsersbyId, storeUsers, updateUsersById, associateUserOrganizationByUserId, disassociateUserOrganizationByUserId, passwordResetUsersById } from 'services/jarvisApi/usersGlobal'
import yupValidationResolver from 'utils/yupValidationResolver'

interface IParams{
  params: {
    id: string;
  }
}

const CreateOrUpdate: React.FC = () => {
  const { params }: IParams = useRouteMatch()
  const [userOrganizations, setUserOrganizations] = useState([])
  const [userInActualOrganization, setUserInActualOrganization] = useState(true)
  const [serverError, setServersError] = useState([])
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const { getCurrentOrganization } = useOrganization()
  const { user } = useUser()

  const columns: { title: string, field: string}[] = [
    { title: 'CNPJ', field: 'document_number' },
    { title: 'Organização', field: 'display_name' }
  ]

  const validationSchema = yup.object().shape({
    name: yup.string().required('Campo Obrigatório'),
    email: yup.string().email().required('Campo Obrigatório')
  })

  const methods = useForm({
    resolver: yupValidationResolver(validationSchema)
  })

  useEffect(() => {
    params.id && getUsersbyId(params.id)
      .then((response) => {
        methods.reset(response.data)
        setUserInActualOrganization(response.data.organizations.some((org: any) => org.id === getCurrentOrganization()))
        setUserOrganizations(response.data.organizations)
      })
  }, [params.id])

  const onSubmit = useCallback(async (data: any) => {
    (params.id
      ? updateUsersById(params.id, data)
      : storeUsers({ ...data, organization_id: getCurrentOrganization() }))
      .then(({ data }) => {
        history.push(`/users/${data.id}`)
        enqueueSnackbar(`${params.id ? 'Editado' : 'Novo usuario'} salvo com sucesso`, { variant: 'success' })
      })
      .catch(({ response }) => {
        setServersError(response.data?.errors)
        enqueueSnackbar('Ocorreu um erro, verifique os dados digitados.', { variant: 'error' })
      })
  }, [])

  const handleAssociateOrganization = () => {
    associateUserOrganizationByUserId(params.id, { user_id: params.id, organization_id: getCurrentOrganization() })
      .then(() => {
        enqueueSnackbar('Usuário associado com sucesso.', { variant: 'success' })
        setUserInActualOrganization(true)
      })
      .catch(err => {
        console.error(err)
        enqueueSnackbar('Ocorreu um erro ao associar o usuário.', { variant: 'error' })
      })
  }

  const handleDisassociateOrganization = () => {
    disassociateUserOrganizationByUserId(params.id)
      .then(() => {
        enqueueSnackbar('Usuário disassociado com sucesso.', { variant: 'success' })
        setUserInActualOrganization(false)
      })
      .catch(err => {
        console.error(err)
        enqueueSnackbar('Ocorreu um erro ao disassociado o usuário.', { variant: 'error' })
      })
  }

  const handleResetPassword = () => {
    passwordResetUsersById(params.id)
      .then(() => {
        enqueueSnackbar('Redifinição de senha realizada. E-mail com a nova senha foi enviada para o e-mail do usuário.', { variant: 'success' })
      })
      .catch(err => {
        console.error(err)
        enqueueSnackbar('Ocorreu um erro ao tentar redefinir senha do usuário.', { variant: 'error' })
      })
  }

  return (
    <OverPage>
      <Page title={ params.id ? `Atualizar Usuário #${params.id}` : 'Novo Usuário' } backTo>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={4}>
            <Paper square>
              <Box p={2} width={1}>
                <Form onSubmit={onSubmit} methods={methods} serverErrors={serverError}>
                  <Box p={1} width={1}>
                    <Input name="name" label="Nome do Usuário" startAdornment={FaBuilding} fullWidth/>
                  </Box>
                  <Box p={1} width={1}>
                    <Input name="email" label="E-mail" startAdornment={FaBuilding} fullWidth/>
                  </Box>
                  <Box p={1} width={1}>
                    <Button color="primary" type="submit" fullWidth>
                      Enviar
                    </Button>
                  </Box>
                </Form>
              </Box>
            </Paper>
          </Grid>
          {params.id && Number(user.id) !== Number(params.id) && <Grid item xs={12} md={3}>
            <Paper square>
              <Box p={2} width={1}>
                <Box p={1} width={1} display="flex" justifyContent="center">
                  <Typography variant="body1">Ações Rápidas </Typography>
                </Box>
                <Box p={1} width={1}>
                  <Button disabled={userInActualOrganization} onClick={handleAssociateOrganization} startIcon={<MdAddCircleOutline />} color="primary" type="submit" fullWidth>
                      Associar a esta organização
                  </Button>
                </Box>
                <Box p={1} width={1}>
                  <Button disabled={!userInActualOrganization} onClick={handleDisassociateOrganization} startIcon={<MdRemoveCircleOutline />} color="primary" type="submit" fullWidth>
                      Disassociar a esta organização
                  </Button>
                </Box>
                <Box p={1} width={1}>
                  <Button startIcon={<MdLock />} onClick={handleResetPassword} color="primary" type="submit" fullWidth>
                      Resetar Senha
                  </Button>
                </Box>
              </Box>
            </Paper>
          </Grid>}
          {params.id && !!userOrganizations.length &&
          <Grid item xs={12} md={8}>
            <Table
              data={userOrganizations}
              columns={columns}
              options={{ actionsColumnIndex: -1, pageSize: 5 }}
            />
          </Grid>}
        </Grid>
      </Page>
    </OverPage>
  )
}

export default CreateOrUpdate
