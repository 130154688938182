import React, { useState } from 'react'
import { FaExternalLinkAlt } from 'react-icons/fa'

import { Menu, MenuItem, Button, Box } from '@material-ui/core'

type IPrintButton = {
  complaintId: string
}

const PrintButton: React.FC<IPrintButton> = ({ complaintId }) => {
  const [anchorEl, setAnchorEl] = useState<null | Element>(null)

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <Button color="primary" aria-controls="print-menu" variant="text" aria-haspopup="true" onClick={handleClick}>
        Imprimir <Box ml={1}><FaExternalLinkAlt /></Box>
      </Button>
      <Menu
        id="print-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <a target="_blank" rel="noreferrer" href={`/complaint/${complaintId}/print?type=complete`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem onClick={handleClose}>
            Completo
          </MenuItem>
        </a>
        <a target="_blank" rel="noreferrer" href={`/complaint/${complaintId}/print?type=simplified`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <MenuItem onClick={handleClose}>
            Simplificada
          </MenuItem>
        </a>
      </Menu>
    </>
  )
}

export default PrintButton
