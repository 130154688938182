/* eslint-disable no-unused-vars */
import React from 'react'

import { Box, Card as MuiCard, CardContent, Typography, Grid, Tooltip as MuiTooltip } from 'components'

import { isUndefined } from 'lodash'
import { BarChart as ReBarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip, Legend } from 'recharts'

import { ReactComponent as EmptySvg } from 'assets/svg/empty.svg'

interface ILineChartComposed {
  label: string,
  data: {name: string, [key: string]: string | number}[],
  bars: string[],
  total?: number,
  totalType?: string
  totalTooltip?: string
}

const LineChartComposed: React.FC<ILineChartComposed> = ({ data, bars, label, total, totalType, totalTooltip }) => {
  const COLORS = ['#00BCD4', '#2980b9', '#8e44ad', '#e67e22', '#1abc9c', '#ED4C67']

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }: any) => {
    const RADIAN = Math.PI / 180
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5
    const x = cx + radius * Math.cos(-midAngle * RADIAN)
    const y = cy + radius * Math.sin(-midAngle * RADIAN)

    return (
      <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    )
  }

  const RenderTooltip = ({ title, children }: any) => {
    if (title) {
      return (
        <MuiTooltip title={title} arrow>
          {children}
        </MuiTooltip>
      )
    }
    return children
  }

  const RenderNoData = ({ dataLength, children }: any) => {
    if (!dataLength) {
      return (
        <Box display="flex" justifyContent="center">
          <EmptySvg width="270" height="350" />
        </Box>
      )
    }
    return children
  }

  return (
    <Box>
      <MuiCard>
        <CardContent>
          <Grid container justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography component="h5" variant="h5">
                {label}
              </Typography>
            </Grid>
            <RenderNoData dataLength={data.length}>
              {!isUndefined(total) && (
                <Grid item xs={4}>
                  <RenderTooltip title={totalTooltip}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      m="auto"
                      color={`${totalType}.contrastText`}
                      bgcolor={`${totalType}.main`}
                      p={2}
                      borderRadius={16}
                    >
                      <Typography component="h3" variant="h3">
                  0
                      </Typography>
                    </Box>
                  </RenderTooltip>
                </Grid>
              )}
              <Grid item xs={!isUndefined(total) ? 8 : 12}>
                <ResponsiveContainer height={400}>
                  <ReBarChart
                    width={500}
                    height={300}
                    data={data}
                    maxBarSize={100}
                    margin={{
                      top: 20,
                      right: 30,
                      left: 20,
                      bottom: 5
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    {bars.map((key, idx) => {
                      return <Bar
                        key={idx}
                        dataKey={key}
                        stackId='a'
                        fill={COLORS[idx % COLORS.length]} />
                    })}
                  </ReBarChart>
                </ResponsiveContainer>
              </Grid>
            </RenderNoData>
          </Grid>
        </CardContent>
      </MuiCard>
    </Box>)
}

export default LineChartComposed
