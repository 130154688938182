import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import PageNotFound from 'components/PageNotFound'
import PrivateRoute from 'components/PrivateRoute'

import privateRoutes from './private'
import publicRoutes from './public'

const Routes: React.FC = () => (
  <BrowserRouter>
    <Switch>
      { publicRoutes.map(({ path, component, ...rest }) => (
        <Route key={path} path={path} component={component} exact {...rest}/>
      )) }

      { privateRoutes.map(({ path, component, ...rest }, index) => (
        <PrivateRoute key={index} path={path} component={component} exact {...rest}/>
      )) }
      <Route path="*">
        <PageNotFound />
      </Route>
    </Switch>
  </BrowserRouter>
)

export default Routes
