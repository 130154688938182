import { useTheme } from '@material-ui/core/styles'

interface IStatus {
  [key: string]: {
    text: string,
    color: string
  }
}

const useStatus = () : IStatus => {
  const theme = useTheme()

  return {
    NEW: { text: 'Novo', color: theme.palette.info.light },
    IN_REVIEW: { text: 'Em revisão', color: theme.palette.info.main },
    WAITING_FOR_MORE_INFORMATION: { text: 'Aguardando mais informações', color: theme.palette.info.dark },
    DONE: { text: 'Concluído', color: theme.palette.success.dark },
    IN_ANALYSIS: { text: 'Em análise', color: theme.palette.warning.dark },
    PENDING: { text: 'Pendente', color: theme.palette.error.dark },
    REOPENED: { text: 'Reaberto', color: theme.palette.warning.dark }
  }
}

export default useStatus
