import React, { useCallback } from 'react'
import { useFormContext, useController } from 'react-hook-form'

import { TextField, AutoComplete } from 'components'

import { makeStyles } from '@material-ui/core/styles'
import { AutocompleteProps, AutocompleteRenderInputParams, createFilterOptions } from '@material-ui/lab/Autocomplete'
import { get } from 'lodash'

import ListBoxComponent from './components/ListBoxComponent'

interface ISelectProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> {
  name: string;
  label: string;
  allowNewValue?: boolean;
  startAdornment?: React.ReactNode | React.ComponentType;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode
}

const useStyles = makeStyles({
  listbox: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0
    }
  }
})

const filter = createFilterOptions<any>()

const Select = <
    T extends unknown,
    Multiple extends boolean | undefined,
    DisableClearable extends boolean | undefined,
    FreeSolo extends boolean | undefined
  >(props: ISelectProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const { name, label, startAdornment: StartAdornment, ...rest } = props
  const classes = useStyles()

  const { setValue } = useFormContext()

  const { field, meta } = useController({ name })
  const error = meta.invalid ? { message: 'Dado inválido' } : null

  const isMultiple = useCallback(() => Boolean(rest.multiple), [props])

  const isNewValue = useCallback((inputValue, filtredValues, selectedValues) => {
    return Boolean(inputValue.length) &&
      !filtredValues.includes(inputValue) &&
      !selectedValues.some((value: string) => value === inputValue)
  }, [])

  const filterOptions = useCallback((options: any, params: any) => {
    const filtered = filter(options, params)
    const values = Array.isArray(field.value) ? field.value : [field.value]

    if (rest.allowNewValue && isNewValue(params.inputValue, filtered, values) && rest.freeSolo) {
      filtered.push(`Adicionar "${params.inputValue}"`)
    }

    return filtered
  }, [field])

  return (
    <AutoComplete
      id={`select-auto-complete-${name}`}
      disableListWrap
      classes={classes}
      ListboxComponent={ListBoxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
      value={get(field, 'value', isMultiple() ? [] : '')}
      onChange={(e, options) => setValue(name, options)}
      ref={field.ref}
      filterOptions={filterOptions}
      noOptionsText="Nenhuma opção encontrada"
      renderInput={(params) => (
        <TextField
          error={!!error}
          helperText={!!error && error.message}
          name={name}
          variant="outlined"
          autoComplete="false"
          label={label}
          {...params}
        />
      )}
      {...rest}
    />
  )
}

export default Select
