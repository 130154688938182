import React from 'react'

import { IInputBaseProps } from '../InputBase'
import { InputBaseStyled } from './styles'

const Textarea: React.FC<IInputBaseProps> = (props) => {
  return <InputBaseStyled multiline minRows={4} {...props}/>
}

export default Textarea
