import { AppBar } from 'components'

import styled from 'styled-components'

export const FormContainer = styled.div`

  padding: 16px;

  .MuiButton-root {
      left: calc(50% - 120px);
      margin-top: 16px;
      padding-left: 80px;
      padding-right: 80px;
    }

  form {
    display: block;

    .MuiFormControl-root {
      margin-bottom: ${props => props.theme.spacing(3)}px;
    }
  }

`

export const AppBarStyled = styled(AppBar)`
  margin-bottom: 8px
`
