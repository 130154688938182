import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'

import {
  Container,
  Grid,
  Box
} from 'components'

import { ReactComponent as PageNotFoundSvg } from 'assets/svg/page_not_found.svg'
import useStatus from 'hooks/status'
import { getComplaintById } from 'services/jarvisApi/complaint'

import Comments from './components/Comments'
import Header from './components/Header'
import Questions from './components/Questions'
import Trackings from './components/Trackings'

interface IParams{
  params: {
    id: string;
    type?: string;
  }
}

interface IComplaintData {
  status: {
    color: string
    text: string
  }
  name: string
  mail: string
  phone: string
  closed_at: string
  created_at: string
  term_accept: boolean
  access_key: string
  comments: {
    id: number,
    author: string,
    message: string,
    created_at: string,
    updated_at: string
  }[]
  trackings: {
    id: number
    complaint_id: number
    description: string
    tracking: string
    user_id: string
    user: {
      email: string
      id: string
    }
  }[]
}

const Print:React.FC = () => {
  const [complaintData, setComplaintData] = useState({ status: { color: '', text: '' } } as IComplaintData)
  const [printType, setPrintType] = useState('complete')
  const { params }: IParams = useRouteMatch()
  const statusHook = useStatus()
  const queryString = new URLSearchParams(window.location.search)

  const { data, error } = useQuery(`complaint_${params.id}`,
    () => getComplaintById(params.id).then(({ data }) => data),
    { refetchOnWindowFocus: false, retry: false }
  )

  useEffect(() => {
    setPrintType(queryString.get('type') || 'complete')
    if (data?.id) {
      setComplaintData({
        ...data,
        status: {
          color: statusHook[data.status].color,
          text: statusHook[data.status].text
        }
      })
    }
  }, [data])

  return (
    <Container justifyContent="center">
      {error && (
        <PageNotFoundSvg />
      )}
      {!error && (
        <Grid container spacing={1}>
          <Box p={1} width={1}>
            <Grid item xs={12}>
              <Header data={complaintData} />
            </Grid>
            <Grid item xs={12}>
              <Questions data={complaintData} />
            </Grid>
            {Boolean(complaintData?.trackings?.length) && printType !== 'simplified' && (
              <Grid item xs={12}>
                <Trackings data={complaintData.trackings} />
              </Grid>
            )}
            {Boolean(complaintData?.comments?.length) && (
              <Grid item xs={12}>
                <Comments data={complaintData.comments} />
              </Grid>
            )}
          </Box>
        </Grid>
      )}
    </Container>
  )
}

export default Print
