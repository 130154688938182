import React, { Fragment } from 'react'

import { Divider, Grid, Paper, Box, Button, Avatar } from 'components'
import { Form, Textarea } from 'components/HookForm'

import { decode } from 'html-entities'
import { DateTime } from 'luxon'

interface ICommentBox {
  onSubmit(data: object): void;
  methods: object
  data?: []

}

const CommentBox = ({ onSubmit, methods, data = [] }: ICommentBox) => {
  return (
    <Paper elevation={0}>
      {data && data.map(({ author, message, created_at: createdAt }, idx) => (
        <Fragment key={`comment_box_${idx}`}>
          <Grid container wrap="nowrap" spacing={2}>
            <Grid item>
              <Avatar alt={author} />
            </Grid>
            <Grid item xs zeroMinWidth>
              <h4 style={{ margin: 0, textAlign: 'left' }}>{author}</h4>
              <p style={{ textAlign: 'left' }}>
                {decode(message, { level: 'all' })}
              </p>
              <p style={{ textAlign: 'left', color: 'gray' }}>
                {DateTime.fromISO(createdAt).toFormat('dd/MM/yyyy HH:mm:ss')}
              </p>
            </Grid>
          </Grid>
          <Divider variant="fullWidth" style={{ margin: '30px 0' }} />
        </Fragment>
      ))}

      <Form onSubmit={onSubmit} methods={methods}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12}>
            <Textarea fullWidth label="Digite sua mensagem" name="message" />
          </Grid>
          <Grid item xs={12}>
            <Box textAlign="center">
              <Button color="primary" variant="contained" type="submit">Enviar</Button>
            </Box>
          </Grid>
        </Grid>
      </Form>
    </Paper>)
}

export default CommentBox
