import React from 'react'

import { Grid, GridProps } from '@material-ui/core'

import { ContainerStyled, PaperStyled } from './styles'

interface IContainerItem extends GridProps{
  paper?: boolean;
}

const Container: React.FC<IContainerItem> = ({ children, paper = false, ...rest }) => (
  <ContainerStyled>
    {paper
      ? (
        <PaperStyled>
          <Grid spacing={3} {...rest} container>
            {children}
          </Grid>
        </PaperStyled>
      )
      : (
        <Grid spacing={3} {...rest} container>
          {children}
        </Grid>
      )}
  </ContainerStyled>
)

export default Container
