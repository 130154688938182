import React, { useState, useCallback } from 'react'
import { useFormContext, Controller } from 'react-hook-form'

import {
  InputAdornment,
  BaseTextFieldProps,
  InputProps,
  FilledInputProps,
  OutlinedInputProps
} from '@material-ui/core'

import { TextFieldStyled } from './styles'

export interface IInputBaseProps extends BaseTextFieldProps {
    name: string;
    label: string;
    startAdornment?: React.ComponentType | React.ReactNode;
    endAdornment?: React.ComponentType | React.ReactNode;
    inputComponent?: React.ReactNode;
    InputProps?: Partial<InputProps> | Partial<FilledInputProps> | Partial<OutlinedInputProps>;
    onChange?: any;
  }

export const InputBase: React.FC<IInputBaseProps> = ({
  name,
  startAdornment: IconComponentStart,
  endAdornment: IconComponentEnd,
  inputComponent: InputComponent,
  InputProps = {},
  ...rest

}) => {
  const { control, errors, getValues } = useFormContext()

  // Funções responsaveis pela manipulação das cores dos icones
  const [isFocused, setIsFocused] = useState(false)
  const [isFilled, setIsFilled] = useState(false)

  const handleInputFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const handleInputBlur = useCallback(() => {
    setIsFocused(false)

    if (getValues()[name]) {
      setIsFilled(true)
    } else {
      setIsFilled(false)
    }
  }, [])

  const adorment = useCallback((Icon, position) => {
    return (
      <InputAdornment position={position}>
        <Icon size={20}/>
      </InputAdornment>
    )
  }, [])

  return (
    <Controller
      render={(props) => {
        return (
          <TextFieldStyled
            value={props.value}
            $isFilled={isFilled}
            $isFocused={isFocused}
            $isErrored={!!errors[name]}
            inputRef={props.ref}
            onBlur={() => { handleInputBlur() }}
            onFocus={handleInputFocus}
            onChange={props.onChange}
            variant="outlined"
            error={!!errors[name]}
            helperText={!!errors[name] && errors[name].message}
            InputProps={{
              startAdornment: !!IconComponentStart && adorment(IconComponentStart, 'start'),
              endAdornment: !!IconComponentEnd && adorment(IconComponentEnd, 'end'),
              ...InputProps
            }}
            {...rest}
          />
        )
      }}
      control={control}
      name={name}
      defaultValue=""
    />
  )
}
