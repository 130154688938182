import React, { useEffect } from 'react'
import { FormProvider } from 'react-hook-form'

import { FormStyled } from './styles'

interface IForm {
  id?: any;
  methods: any;
  onSubmit(data?: object): void;
  serverErrors?: {
    field: string;
    message: string;
  }[];
}

const Form: React.FC<IForm> = ({ methods, children, onSubmit, serverErrors = [], id = '' }) => {
  const { handleSubmit } = methods

  useEffect(() => {
    serverErrors.map((error) => {
      return methods.setError(error.field, {
        message: error.message
      })
    })
  }, [serverErrors])

  return (
    <FormProvider {...methods}>
      <FormStyled onSubmit={handleSubmit(onSubmit)} id={id}>
        { children }
      </FormStyled>
    </FormProvider>
  )
}
export default Form
