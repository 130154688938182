import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { FaEnvelope, FaLock, FaKey } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'

import { Button, Container, ContainerItem } from 'components'
import { Form, Input, InputPassword } from 'components/HookForm'

import { Box, Typography, Divider } from '@material-ui/core'
import * as yup from 'yup'

import { ReactComponent as AccessAccountSvg } from 'assets/svg/access_account.svg'
import { useAuth } from 'hooks/auth'
import { useUser } from 'hooks/user'
import { ILoginData } from 'services/jarvisApi/auth'
import validationResolver from 'utils/yupValidationResolver'

import { PageStyled } from './styles'

const Sessions: React.FC = () => {
  const { authenticate } = useAuth()
  const { getMe } = useUser()
  const [error, setError] = useState(null as string | null)
  const history = useHistory()

  const validationSchema = yup.object().shape({
    email: yup.string().email('E-mail com formato incorreto').required('E-mail requerido'),
    password: yup.string().required('Senha requerida')
  })

  const resolver = validationResolver(validationSchema)

  const methods = useForm({ resolver })

  const onSubmit = async (data:ILoginData) => {
    try {
      await authenticate(data)
      await getMe()
      history.push('/')
    } catch (error) {
      if (error.response && error.response?.status === 400) {
        return setError('E-mail ou senha incorretos')
      } else if (error.response && error.response?.status >= 500) {
        return setError('Ocorreu um erro no servidor, tente mais tarde.')
      }
      setError('Erro ao conectar no servidor, verifique sua internet.')
      console.error(error)
    }
  }

  return (
    <PageStyled>
      <Container >
        <ContainerItem md={6}>
          <Box height={2 / 4} display={{ xs: 'none', md: 'block' }}>
            <AccessAccountSvg height="100%" />
          </Box>
        </ContainerItem>
        <ContainerItem md={1}>
          <Box height={2 / 4} display={{ xs: 'none', md: 'block' }}>
            <Divider orientation="vertical" variant="middle" />
          </Box>
        </ContainerItem>
        <ContainerItem xs={12} md={3} paper>
          <Box display="flex" alignItems="center" flexDirection="column" mt={2}>
            <FaLock size={24}/>
            <Typography> Administração </Typography>
          </Box>
          <Form onSubmit={onSubmit} methods={methods}>
            <Input name="email" label="E-mail" startAdornment={FaEnvelope} fullWidth/>
            <InputPassword name="password" startAdornment={FaKey} label="Password" fullWidth/>
            <Button type="submit" color="primary" fullWidth> Entrar </Button>
            <Box mt={1} display="flex" justifyContent="center" width={1}>
              { error && <Typography align="center" color="error"> {error} </Typography> }
            </Box>
          </Form>
        </ContainerItem>
      </Container>
    </PageStyled>
  )
}

export default Sessions
