import { FaHome, FaBuilding, FaUsers, FaUserCircle } from 'react-icons/fa'
import { MdAssignment, MdVerifiedUser } from 'react-icons/md'

import permissions from 'commons/constants/permissions'

const sidebarMenu = [
  {
    title: 'Home',
    icon: FaHome,
    url: '/',
    roles: []
  },
  {
    title: 'Triagem',
    icon: MdVerifiedUser,
    url: '/screening',
    roles: [permissions.COMPLAINT_SCREENING]
  },
  {
    title: 'Denúncias',
    icon: MdAssignment,
    url: '/complaint',
    roles: []
  },
  {
    title: 'Usuários',
    icon: FaUsers,
    url: '/users',
    roles: [permissions.USER_ADMIN]
  },
  {
    title: 'Organizações',
    icon: FaBuilding,
    url: '/organizations',
    roles: [permissions.ORGANIZATION_ADMIN]
  },
  {
    title: 'Minha conta',
    icon: FaUserCircle,
    url: '/my-account',
    roles: []
  }
]

export default sidebarMenu
