import { useTheme } from '@material-ui/core/styles'

interface IStatus {
  [key: string]: {
    text: string,
    color: string
  }
}

const useAttachmentStatus = () : IStatus => {
  const theme = useTheme()

  return {
    PENDING: { text: 'Pendente', color: theme.palette.primary.dark },
    IN_RISK_ANALYSIS: { text: 'Em análise de risco', color: theme.palette.warning.dark },
    MALWARE_DETECTED: { text: 'Vírus detectado', color: theme.palette.error.main },
    FAILED: { text: 'Verificação falhou', color: theme.palette.error.dark },
    DONE: { text: 'Concluído', color: theme.palette.success.dark }
  }
}

export default useAttachmentStatus
