import styled from 'styled-components'

interface StatusProps {
  readonly backgroundColor: string;
}

export const StatusStyled = styled.span<StatusProps>`
    color: #fff;
    display: inline-block;
    padding: 8px 20px;
    border-radius: 30px;
    background-color: ${(props) => props.backgroundColor || ''}
`
