import React, { useRef, useEffect, useState } from 'react'
import { MdFormatListBulleted as MdFormatListBulletedIcon, MdClear as MdClearIcon } from 'react-icons/md'
import { useHistory } from 'react-router-dom'

import { Page, Container, ContainerItem, Table, Fab, Box } from 'components'
import CustomDatePicker from 'components/Table/components/CustomDatePicker'

import { Typography, useTheme } from '@material-ui/core'
import { isDate, format } from 'date-fns'
import _ from 'lodash'
import difference from 'lodash/difference'
import { DateTime } from 'luxon'
import { Column, Query } from 'material-table'

import useCheckoutStatus from 'hooks/checkoutStatus'
import useStatus from 'hooks/status'
import { getAll } from 'services/jarvisApi/complaint'

interface IComplaintData {
  owner?: string
  status: string
  created_at: string
  closed_at?: string
  checkout_status: string
}

const Complaint: React.FC = () => {
  const [hasFilterOnSession, setHasFilterOnSession] = useState(false)
  const [hasChangedFilter, setHasChangedFilter] = useState(false)
  const clientTableRef = useRef<any>({ onQueryChange: () => {} })
  const sessionStorageCacheKey = 'complaint:list:filters'

  useEffect(() => {
    setHasFilterOnSession(Boolean(sessionStorage.getItem(sessionStorageCacheKey)))
  }, [hasChangedFilter])

  const router = useHistory()

  const theme = useTheme()

  const statusHook = useStatus()
  const checkoutStatusHook = useCheckoutStatus()

  CustomDatePicker.displayName = 'Teste'

  const isOwnerChangeColor = (owner?: string): 'primary' | 'error' => {
    if (owner === 'Comitê de Compliance') {
      return 'primary'
    }
    return 'error'
  }

  const columns: Column<IComplaintData>[] = [
    { title: 'ID', field: 'id', type: 'numeric', align: 'left', filterCellStyle: { float: 'left' } },
    { title: 'Tema', field: 'topic.group' },
    { title: 'Chave de Acesso', field: 'access_key' },
    // eslint-disable-next-line react/display-name
    { title: 'Responsável', field: 'owner', render: (row) => (<Typography color={isOwnerChangeColor(row.owner)}>{row.owner}</Typography>) },
    {
      title: 'Data de Criação',
      render: (row) => DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy'),
      field: 'created_at',
      type: 'date',
      defaultSort: 'desc',
      dateSetting: { locale: 'pt-BR', format: 'dd/MM/yyyy' }
    },
    {
      title: 'Data da Conclusão',
      render: (row) => row.closed_at ? DateTime.fromISO(row.closed_at).toFormat('dd/MM/yyyy') : null,
      field: 'closed_at',
      type: 'date',
      defaultSort: 'desc',
      dateSetting: { locale: 'pt-BR', format: 'dd/MM/yyyy' }
    },
    {
      title: 'Procedência',
      field: 'checkout_status',
      lookup: _.mapValues(checkoutStatusHook, (value) => value.text),
      render: (row) => ((row.checkout_status && checkoutStatusHook[row.checkout_status].text) || checkoutStatusHook.NOT_CLASSIFIED.text)
    },
    { title: 'Status', field: 'status', lookup: _.mapValues(statusHook, (value) => value.text) }
  ]

  const editBtn = <MdFormatListBulletedIcon size={20} color={theme.palette.primary.main}/>

  const fetchRemoteData = async (query: Query<IComplaintData>) => {
    const filtersBeforeFetchData = JSON.parse(sessionStorage.getItem(sessionStorageCacheKey) || '[]')
    const params = {
      page: query.page + 1,
      pageSize: query.pageSize,
      filters: []
    } as any

    if (query.orderBy) {
      Object.assign(params, {
        orderBy: query.orderBy.field,
        orderDirection: query.orderDirection
      })
    }

    if (query.filters.length > 0) {
      const filters = query.filters
        .filter(filter => Boolean(filter.column.field))
        .filter(filter => {
          if (Array.isArray(filter.value.length)) {
            return filter.value.length > 0
          }
          return filter.value
        })
        .map(filter => {
          if (isDate(filter.value)) {
            const date = format(filter.value, 'yyyy-MM-dd')
            return { [`${filter.column.field}`]: date }
          }
          return { [`${filter.column.field}`]: filter.value }
        })
      // FIXME: existe um bug que não é possível desfazer o filtro
      sessionStorage.setItem(sessionStorageCacheKey, JSON.stringify(filters))

      const hasDifference = difference(filtersBeforeFetchData, filters).length > 0
      setHasChangedFilter(hasDifference)
    }

    const filtersOnSession = JSON.parse(sessionStorage.getItem(sessionStorageCacheKey) || '[]')
    if (filtersOnSession.length > 0) {
      filtersOnSession.forEach((filter: Record<string, any>) => params.filters.push(filter))
    }

    try {
      const { data } = await getAll({ ...params })
      return ({
        data: data.data,
        page: data.meta.current_page - 1,
        totalCount: data.meta.total
      })
    } catch (err) {
      throw new Error(err)
    }
  }

  const handleClearFilters = () => {
    sessionStorage.removeItem(sessionStorageCacheKey)
    setHasFilterOnSession(false)
    clientTableRef.current.retry()
  }

  return (
    <Page title="Denúncias">
      <Container spacing={3}>
        {hasFilterOnSession && <ContainerItem paper={false} xs={12}>
          <Box display="flex" justifyContent="flex-end" alignItems='center'>
            <Fab
              variant="extended"
              color="primary"
              onClick={handleClearFilters}
            >
              <MdClearIcon />
            Limpar Filtros
            </Fab>
          </Box>
        </ContainerItem>}
        <ContainerItem paper={false} xs={12}>
          <Table
            tableRef={clientTableRef}
            data={(query) => fetchRemoteData(query)}
            columns={columns}
            actions={
              [
                {
                  icon: () => editBtn,
                  tooltip: 'Abrir',
                  onClick: (e, rowData) => router.push(`/complaint/${rowData.id}`)
                }
              ]}
            options={{ actionsColumnIndex: -1, pageSize: 10, filtering: true }}
          />
        </ContainerItem>
      </Container>
    </Page>
  )
}

export default Complaint
