import Sessions from 'pages/Sessions'

const route = [
  {
    path: '/login',
    component: Sessions
  }
]

export default route
