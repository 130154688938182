import React, { createContext, useCallback, useContext, useEffect } from 'react'

import localStorageKeys from 'commons/constants/localStorageKeys'

interface IOrganizationContext {
  changeOrganization(OrganizationId: string): void;
  getCurrentOrganization(): string;
}

const OrganizationContext = createContext<IOrganizationContext>({} as IOrganizationContext)

export const OrganizationProvider: React.FC = ({ children }) => {
  const changeOrganization = useCallback((OrganizationId:string, newUrl?: string) => {
    localStorage.setItem(localStorageKeys.JARVIS_ORGANIZATION_KEY, OrganizationId)
    if (newUrl) {
      window.location.href = newUrl
      return
    }
    window.location.reload()
  }, [])

  const getCurrentOrganization = useCallback(() => {
    return localStorage.getItem(localStorageKeys.JARVIS_ORGANIZATION_KEY) || '0'
  }, [])

  useEffect(() => {
    // Função para extrair o organizationId dos parâmetros de consulta
    const getOrganizationIdFromQuery = () => {
      const searchParams = new URLSearchParams(location.search)
      return searchParams.get('organizationId')
    }

    const orgId = getOrganizationIdFromQuery()
    const currentOrg = getCurrentOrganization()
    if (orgId && orgId !== currentOrg) {
      const newUrl = new URL(window.location.href)
      newUrl.searchParams.delete('organizationId')
      changeOrganization(orgId, newUrl.toString())
    }
  }, [location.search])

  return (
    <OrganizationContext.Provider value={{ changeOrganization, getCurrentOrganization }}>
      {children}
    </OrganizationContext.Provider>
  )
}

export function useOrganization () : IOrganizationContext {
  const context = useContext(OrganizationContext)

  if (!context) {
    throw new Error('useOrganization must be used within an OrganizationProvider')
  }

  return context
}
