import axios, { AxiosRequestConfig } from 'axios'

import localStorageKeys from 'commons/constants/localStorageKeys'

const api = (baseURL:string, options:AxiosRequestConfig = {}) => {
  const axiosApi = axios.create({
    baseURL,
    headers: {
      Accept: 'application/json',
      'Accept-Language': 'pt-BR',
      'Content-Type': 'application/json'
    },
    ...options
  })

  axiosApi.interceptors.request.use((config) => {
    const token = localStorage.getItem(localStorageKeys.JARVIS_TOKEN_KEY)
    const organizationId = localStorage.getItem(localStorageKeys.JARVIS_ORGANIZATION_KEY)

    config.headers = {
      ...config.headers,
      Authorization: `bearer ${token}`,
      'x-organization-id': organizationId
    }

    return config
  }, error => {
    throw error
  })

  axiosApi.interceptors.response.use((response) => {
    return response
  }, (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem(localStorageKeys.JARVIS_ORGANIZATION_KEY)
      localStorage.removeItem(localStorageKeys.JARVIS_TOKEN_KEY)

      window.location.replace('/login')
    }

    throw error
  })

  return axiosApi
}

export default api
