import api from './api'

interface IComplaintUpdateData {
  status?: string
  message?: string
  owner?: string
  checkout_status?: string
  checkout_message?: string
  topic_reclassified_id?: string
}

interface IComplaintCloseData {
  feedback?: string
}

export const summary = (startDate: string, endDate: string) => api.get('/complaints/summary', { params: { start_date: startDate, end_date: endDate } })

export const getAll = ({ page, pageSize, orderBy, orderDirection, filters }: any) => api.get('/complaints', { params: { page, pageSize, orderBy, orderDirection, filters } })

export const getComplaintById = (id: string) => api.get(`/complaints/${id}`)

export const getAttachmentById = (id: string, idAttachment: number) => api.get(`/complaints/${id}/attachment/${idAttachment}`)

export const getTrackingAttachmentById = (id: string, idTracking: number, idAttachment: number) => api.get(`/complaints/${id}/tracking/${idTracking}/attachment/${idAttachment}`)

export const updateComplaintById = (id: string, data: IComplaintUpdateData) => api.put(`/complaints/${id}`, data)

export const storeCommentComplaintById = (id: string, data: IComplaintUpdateData) => api.post(`/complaints/${id}/comments`, data)

export const storeTrackingComplaintById = (id: string, data: any) => api.post(`/complaints/${id}/tracking`, data)

export const uploadFinalReport = (id: string, data: any) => api.put(`/complaints/${id}/attachment`, data)

export const updateTrackingComplaintById = (id: string, idTracking: string, data: any) => api.put(`/complaints/${id}/tracking/${idTracking}`, data)

export const deleteTrackingComplaintById = (id: string, idTracking: string) => api.delete(`/complaints/${id}/tracking/${idTracking}`)

export const getTrackingComplaintById = (id:string, page:number, pageSize:number) => api.get(`/complaints/${id}/tracking`, { params: { page, pageSize } })

export const closeComplaintById = (id: string, data: IComplaintCloseData) => api.put(`/complaints/${id}/closeComplaint`, data)
