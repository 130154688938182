import { TextField } from '@material-ui/core'
import styled, { css } from 'styled-components'

interface IContainer {
  $isFocused: boolean;
  $isFilled: boolean;
  $isErrored: boolean;
}

export const TextFieldStyled = styled(TextField)<IContainer>`
  svg {
    transition: color 300ms;
    ${props => props.$isFocused && css`color: ${props.theme.palette.primary.light};`}
    ${props => props.$isFilled && css`color: ${props.theme.palette.primary.light};`}
    ${props => props.$isErrored && css`color: ${props.theme.palette.error.main};`}
}
`
