// @ts-nocheck eslint-disable
import React from 'react'
import { useDropzone, DropzoneOptions, DropzoneState } from 'react-dropzone'
import { useFormContext, Controller } from 'react-hook-form'
import { MdAttachFile as AttachFile } from 'react-icons/md'

import { Typography, Chip, ListItem } from '@material-ui/core'
import styled from 'styled-components'

const toBase64 = (file: File) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  const base = {
    name: file.name,
    size: file.size
  }
  reader.readAsDataURL(file)
  reader.onload = () => resolve({
    ...base,
    encoded: reader.result
  })
  reader.onerror = error => reject(error)
})

const DivStyled = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${({ theme, isDragActive, isDragAccept, isDragReject }: { theme: any } & DropzoneState) => {
    const { primary, error, success } = theme.palette
    let dragStatus = null
    if (isDragActive) {
      dragStatus = primary.main
    } else if (isDragAccept) {
      dragStatus = success.main
    } else if (isDragReject) {
      dragStatus = error.main
    }
    return dragStatus
  }};
    border-style: dashed;
    background-color: #afafa;
    color: #dbdbd;
    outline: none;
    transition: border.24s ease-in-out;
`

const Dropzone = ({
  multiple,
  onChange,
  serverError,
  ...rest
}: DropZoneFieldType) => {
  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    acceptedFiles,
    rejectedFiles,
    fileRejections
  } = useDropzone({
    multiple,
    maxFiles: 10,
    maxSize: 10485760,
    ...rest
  })

  const onDrop = (file: File) => {
    const reader = new FileReader()
    reader.onload = (event) => {}
    return reader.readAsDataURL(file)
  }

  const errorMessages = {
    'file-too-large': 'Tamanho máximo excedido',
    'too-many-files': 'Limite de arquivos excedido',
    'file-invalid-type': 'Tipo de arquivo não suportado'
  }

  const maxSize = rest.maxSize ? rest.maxSize : 99999999999

  const isFileTooLarge = Array.isArray(rejectedFiles) && rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize
  return (
    <div className='container'>
      <DivStyled {...getRootProps({ isDragActive, isDragAccept, isDragReject, className: 'dropzone' })}>
        <input {...getInputProps({ onChange, onDrop })} />
        {!isDragActive && (
          <>
            <Typography variant='body1'>Clique aqui ou arraste solte seus arquivos</Typography>
            <Typography variant='caption'>Limite de até {rest.filesLimit} arquivos</Typography>
            <Typography variant='caption'>Tamanho máximo de cada arquivo é de {(maxSize / 1000000).toFixed(0)} Megabytes</Typography>
          </>
        )}
        {isDragReject && <Typography variant='body2'>Arquivo não suportado {fileRejections}</Typography>}
        {fileRejections && fileRejections.map(({ file, errors }) => {
          return (
            <>
              <Typography variant='body2' color='error'>Arquivo: {file.name} </Typography>
              {errors.map(({ code }) => <Typography key={code} variant='caption' color='error'>{errorMessages[code]}</Typography>)}
            </>
          )
        })}
        {isFileTooLarge && (
          <Typography variant='body1'>Arquivo(s) excedeu o tamanho máximo</Typography>
        )}
        {serverError && (
          <Typography variant='caption' color='error'>Arquivo inválido</Typography>
        )}
      </DivStyled>
      {acceptedFiles.map(file => (
        <ListItem key={file.path}>
          <Chip variant='outlined' color='primary' label={`${file.path} - ${file.size / 1000} Kbytes`} icon={<AttachFile />} />
        </ListItem>
      ))}
    </div>
  )
}

type DropZoneFieldType = {
  name: string
  transformBase64?: boolean
  filesLimit: number
  fullWidth: boolean
} & DropzoneOptions

const DropzoneField = ({
  name,
  multiple,
  transformBase64 = false,
  ...rest
}: DropZoneFieldType) => {
  const { control, errors } = useFormContext()

  return (
    <Controller
      render={({ onChange }) => (
        <Dropzone
          multiple={multiple}
          serverError={Boolean(errors[name])}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const multipleFiles = multiple ? e.target.files : e.target.files[0]
            if (transformBase64) {
              return Promise.all(Object.values(multipleFiles)
                .map(toBase64)).then(onChange)
            }
            return onChange(multipleFiles)
          }
          }
          {...rest}
        />
      )}
      name={name}
      control={control}
      defaultValue=''
    />
  )
}

export default DropzoneField
