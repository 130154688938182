import api from './api'

export interface IScreeningsUpdateData {
  finished_review: boolean;
  involved_users: number[];
}

export const getAll = ({ page, pageSize, orderBy, orderDirection }: any) => api.get('/screenings', { params: { page, pageSize, orderBy, orderDirection } })

export const getById = (id: string) => api.get(`/screenings/${id}`)

export const updateComplaintById = (id: string, data: IScreeningsUpdateData) => api.put(`/screenings/${id}`, data)
