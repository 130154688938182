import { unstable_createMuiStrictModeTheme } from '@material-ui/core/styles'

const theme = unstable_createMuiStrictModeTheme({
  palette: {
    success: {
      light: '#21FD7C',
      main: '#04E762',
      dark: '#02B64D',
      contrastText: '#424342'
    }
  }
})

export default theme
