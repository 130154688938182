import { IRouteProps } from 'IRoute'

import { List, Show, Print } from '../../pages/Complaint'

const path = 'complaint'

const route:IRouteProps[] = [
  {
    path: `/${path}`,
    component: List,
    roles: []
  },
  {
    path: `/${path}/:id`,
    component: Show,
    roles: []
  },
  {
    path: `/${path}/:id/print`,
    component: Print,
    roles: []
  }
]

export default route
