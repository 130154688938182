import React from 'react'

import { GridProps } from '@material-ui/core'

import { Container, PaperStyled } from './styles'

interface IContainerItem extends GridProps{
  paper?: boolean;
}

const ContainerItem: React.FC<IContainerItem> = ({ children, paper = false, ...rest }) => (
  <Container {...rest} item container={false}>
    {paper
      ? <PaperStyled elevation={1}>{children}</PaperStyled>
      : children
    }
  </Container>
)

export default ContainerItem
