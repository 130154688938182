import React, { useRef } from 'react'
import { MdFormatListBulleted as MdFormatListBulletedIcon } from 'react-icons/md'
import { useHistory } from 'react-router-dom'

import { Page, Container, ContainerItem, Table } from 'components'
import CustomDatePicker from 'components/Table/components/CustomDatePicker'

import { useTheme } from '@material-ui/core'
import { DateTime } from 'luxon'
import { Column, Query } from 'material-table'

import { getAll } from 'services/jarvisApi/screening'

interface IComplaintData {
  owner?: string
  status: string
  created_at: string
  closed_at?: string
}

const Complaint: React.FC = () => {
  const clientTableRef = useRef<any>({ onQueryChange: () => {} })

  const router = useHistory()

  const theme = useTheme()

  CustomDatePicker.displayName = 'Teste'

  const columns: Column<IComplaintData>[] = [
    { title: 'ID', field: 'id', type: 'numeric', align: 'left', filterCellStyle: { float: 'left' } },
    { title: 'Tema', field: 'topic.group' },
    { title: 'Chave de Acesso', field: 'access_key' },
    {
      title: 'Data de Criação',
      render: (row) => DateTime.fromISO(row.created_at).toFormat('dd/MM/yyyy'),
      field: 'created_at',
      type: 'date',
      defaultSort: 'desc',
      dateSetting: { locale: 'pt-BR', format: 'dd/MM/yyyy' }
    }
  ]

  const editBtn = <MdFormatListBulletedIcon size={20} color={theme.palette.primary.main}/>

  const fetchRemoteData = async (query: Query<IComplaintData>) => {
    const params = {
      page: query.page + 1,
      pageSize: query.pageSize,
      filters: []
    } as any

    if (query.orderBy) {
      Object.assign(params, {
        orderBy: query.orderBy.field,
        orderDirection: query.orderDirection
      })
    }

    try {
      const { data } = await getAll({ ...params })
      return ({
        data: data.data,
        page: data.meta.current_page - 1,
        totalCount: data.meta.total
      })
    } catch (err) {
      throw new Error(err)
    }
  }

  return (
    <Page title="Triagem de Denúncias">
      <Container spacing={3}>
        <ContainerItem paper={false} xs={12}>
          <Table
            tableRef={clientTableRef}
            data={(query) => fetchRemoteData(query)}
            columns={columns}
            actions={
              [
                {
                  icon: () => editBtn,
                  tooltip: 'Abrir',
                  onClick: (e, rowData) => router.push(`/screening/${rowData.id}`)
                }
              ]}
            options={{ actionsColumnIndex: -1, pageSize: 10, filtering: true }}
          />
        </ContainerItem>
      </Container>
    </Page>
  )
}

export default Complaint
