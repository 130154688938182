import 'date-fns'
import React, { useState, useEffect } from 'react'
import { UseFormMethods } from 'react-hook-form'

import { Button, Grid, Box } from 'components'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from 'components/DialogComponents'
import { Form, Textarea } from 'components/HookForm'

import { decode } from 'html-entities'

interface IComplaintCloseProps {
  onSubmit(formObject?: object): Promise<void>
  methods: UseFormMethods
  openDialog: boolean
  handleDialogClose(): any
  isSending: boolean
  data?: any
}

const ComplaintCloseDialog : React.FC<IComplaintCloseProps> = ({ onSubmit, methods, openDialog, handleDialogClose, isSending, data = {} }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  useEffect(() => {
    const keys = Object.keys(data)
    if (keys) {
      const decodeData = keys
        .map(key => ({ [key]: decode(data[key], { level: 'all' }) }))
        .reduce((acc, x) => {
          for (const key in x) acc[key] = x[key]
          return acc
        }, {})

      return methods.reset(decodeData)
    }
    methods.reset({})
  }, [data])

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-complaint-close" style={{ minHeight: 'calc(100% + 24px)', maxHeight: 'none' }}>
      <DialogContent>
        <Form onSubmit={onSubmit} methods={methods}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
              <DialogTitle id="form-dialog-title" style={{ paddingLeft: 0 }}>Inserir feedback</DialogTitle>
              <Box paddingLeft={0} paddingTop={0}>
                <DialogContentText>
                    Insira sua mensagem abaixo para inserir o feedback na denúncia.
                </DialogContentText>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Textarea
                    name="feedback"
                    label="Digite o feedback"
                    fullWidth
                    minRows={6}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <Box minHeight={25}>
                <DialogActions style={{ justifyContent: 'left' }}>
                  <Button onClick={handleDialogClose} color="primary" disabled={isSending}>
                      Cancelar
                  </Button>
                  <Button type="submit" color="primary" disabled={isSending}>
                      Enviar
                  </Button>
                </DialogActions>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default ComplaintCloseDialog
