import 'date-fns'
import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'

import { Button, Grid, Box, Select, MenuItem } from 'components'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from 'components/DialogComponents'
import { Form } from 'components/HookForm'

import * as yup from 'yup'

import { getTopics } from 'services/jarvisApi/topic'
import yupValidationResolver from 'utils/yupValidationResolver'

interface IComplaintCloseProps {
  onSuccess(formObject?: object): Promise<void>
  openDialog: boolean
  handleDialogClose(): any
  isSending: boolean
  data?: any
}

const ComplaintReclassifiedDialog : React.FC<IComplaintCloseProps> = ({ onSuccess, openDialog, handleDialogClose, isSending }) => {
  const [open, setOpen] = useState(false)
  const { data, status } = useQuery('topics',
    () => getTopics().then(({ data }) => data),
    { refetchOnWindowFocus: false, retry: false }
  )

  useEffect(() => {
    setOpen(openDialog)
  }, [openDialog])

  const validationComplaintOwnerSchema = yup.object().shape({
    topic_reclassified_id: yup.string().required()
  })
  const methods = useForm({
    resolver: yupValidationResolver(validationComplaintOwnerSchema)
  })

  const onSubmit = (formValues: object) => {
    handleDialogClose()
    return onSuccess(formValues)
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="md" open={open} onClose={handleDialogClose} aria-labelledby="form-dialog-complaint-close" style={{ minHeight: 'calc(100% + 24px)', maxHeight: 'none' }}>
      <DialogContent>
        <Form onSubmit={onSubmit} methods={methods}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={11}>
              <DialogTitle id="form-dialog-title" style={{ paddingLeft: 0 }}>Atualizar o tema da denúncia</DialogTitle>
              <Box paddingLeft={0} paddingTop={0}>
                <DialogContentText>
                    Insira o novo tema da denúncia abaixo
                </DialogContentText>
              </Box>
            </Grid>
            <Grid item xs={11}>
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12}>
                  <Select
                    labelId="topic_reclassified_id"
                    id="topic_reclassified_id"
                    name="topic_reclassified_id"
                    onChange={(e) => methods.setValue('topic_reclassified_id', e.target.value)}
                    disabled={(status !== 'success')}
                    fullWidth
                    variant="outlined"
                    {...methods.register('topic_reclassified_id')}
                  >
                    {data && data.map((topicData) => (
                      <MenuItem key={topicData.id} value={topicData.id}>{topicData.group}</MenuItem>
                    ))}
                  </Select>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={11}>
              <Box minHeight={25}>
                <DialogActions style={{ justifyContent: 'left' }}>
                  <Button onClick={handleDialogClose} color="primary" disabled={isSending}>
                      Cancelar
                  </Button>
                  <Button type="submit" color="primary" disabled={isSending}>
                      Salvar
                  </Button>
                </DialogActions>
              </Box>
            </Grid>
          </Grid>
        </Form>
      </DialogContent>
    </Dialog>
  )
}

export default ComplaintReclassifiedDialog
