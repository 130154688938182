import React from 'react'

import { StatusStyled } from './styles'

interface IStatus {
  backgroundColor: string
}

const Status: React.FC<IStatus> = ({ backgroundColor, children }) => {
  return (
    <StatusStyled backgroundColor={backgroundColor}>
      {children}
    </StatusStyled>
  )
}

export default Status
