import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { MdCloudDownload } from 'react-icons/md'
import { useQuery } from 'react-query'
import { useHistory, useRouteMatch } from 'react-router-dom'

import {
  Container,
  Grid,
  Page,
  OverPage,
  Paper,
  Box,
  Typography,
  Divider,
  Status,
  Button
} from 'components'
import { Form, Select } from 'components/HookForm'

import { useTheme } from '@material-ui/core'
import FileSaver from 'file-saver'
import { decode } from 'html-entities'
import { DateTime } from 'luxon'

import { ReactComponent as PageNotFoundSvg } from 'assets/svg/page_not_found.svg'
import useAttachmentStatus from 'hooks/attachment'
import useStatus from 'hooks/status'
import { getAttachmentById } from 'services/jarvisApi/complaint'
import { getById, updateComplaintById } from 'services/jarvisApi/screening'
import type { IScreeningsUpdateData } from 'services/jarvisApi/screening'
import { getUsers } from 'services/jarvisApi/user'

interface IParams{
  params: {
    id: string;
  }
}

interface IAttachment {
  complaint_id: number
  created_at: string
  id: number
  location: string
  name: string
  size: string
  status: string
  updated_at: string
  is_final_report: boolean
}

interface IAnswer {
  id: number
  complaint_id: number
  answer: string
  created_at: string
  question: string
  updated_at: string
}

const Show: React.FC = () => {
  const { params }: IParams = useRouteMatch()
  const statusHook = useStatus()
  const statusAttachmentHook = useAttachmentStatus()
  const router = useHistory()
  const [loading, setLoading] = useState(false)

  const theme = useTheme()

  const { data, status, error } = useQuery(`screening_${params.id}`,
    () => getById(params.id).then(({ data }) => data),
    { refetchOnWindowFocus: false, retry: false }
  )
  const fetchFn = () => getUsers({ page: 1, pageSize: 50, orderBy: 'id', orderDirection: 'asc', filters: { only_not_screening_admins: true } })
    .then(({ data }) => data.data)
  const userQuery = useQuery('users_list', fetchFn, { refetchOnWindowFocus: false, retry: false })

  const handleDownloadAttachment = async (id: number, attachmentName: string) => {
    const { data } = await getAttachmentById(params.id, id)
    return FileSaver.saveAs(data.signedUrl, attachmentName)
  }

  const methods = useForm()

  const onSubmit = async (formValues: any) => {
    setLoading(true)
    try {
      const values: IScreeningsUpdateData = { finished_review: true, involved_users: [] }
      if (formValues.involved_users) {
        const users = formValues.involved_users.map(
          (email: string) => {
            const user = userQuery.data?.find((user) => user.email === email)
            if (!user) return null
            return Number(user.id)
          }
        ).filter((id: number | null) => id !== null)

        values.involved_users = users as number[]
      }
      await updateComplaintById(params.id, values)
      router.replace(`/complaint/${params.id}`)
    } finally {
      setLoading(false)
    }
  }

  return (
    <OverPage>
      <Page title={`Denúncia em triagem #${params.id}` } backTo>
        <Container justifyContent="center">
          {(error || userQuery.error) && (
            <PageNotFoundSvg />
          )}
          {status === 'success' && userQuery.status === 'success' && (
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper>
                  <Box display="flex">
                    <Box component="div" flexGrow={1} p={2}>
                      <Typography variant="h6" display="inline">Informações iniciais</Typography>
                    </Box>
                    <Box component="div" p={2} paddingBottom={1} paddingTop={1}>
                      <Status backgroundColor={statusHook[data.status].color}>
                        <Typography variant="body2" display="inline">
                          {statusHook[data.status].text}
                        </Typography>
                      </Status>
                    </Box>
                  </Box>
                  <Divider />
                  <Box component="div" display="block" p={2}>
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        <Typography>Nome: {data.name || '-'}</Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography>Telefone: {data.phone || '-'}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box component="div" display="block" p={2}>
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        <Typography>E-mail: {data.mail || '-'}</Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography>Data Criação: {DateTime.fromISO(data.created_at).toFormat('dd/MM/yyyy HH:mm')} {data.status === 'DONE' && data.closed_at && `/ Fechado em: ${DateTime.fromISO(data.closed_at).toFormat('dd/MM/yyyy HH:mm')}`}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box component="div" display="block" p={2}>
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        <Typography>Termo: {data.term_accept ? 'Aceito' : 'Não Aceito' }</Typography>
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <Typography>Chave de acesso: {data.access_key}</Typography>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box component="div" display="block" p={2}>
                    <Grid container>
                      <Grid item sm={6} xs={12}>
                        <Typography>Tema: {data.topic_reclassified?.group || data.topic.group} </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Paper>
              </Grid>
              <Grid item xs={3}>
                <Paper>
                  <Box display="flex">
                    <Box component="div" flexGrow={1} p={2}>
                      <Typography variant="h6" display="inline">Opções de triagem</Typography>
                    </Box>
                  </Box>
                  <Divider />
                  <Form onSubmit={onSubmit} methods={methods}>
                    <Box display="flex" p={2} width={1} justifyContent="center" flexWrap="wrap">
                      <Box width={1}><Select name="involved_users" label="Usuário(s) envolvido(s)" fullWidth options={userQuery.data.map((user) => user.email)} freeSolo={false} multiple disabled={userQuery.data.length === 0}/></Box>
                      <Box width={1} mt={1} display="inherit" justifyContent="center"><Button color='primary' type='submit' disabled={loading}>Finalizar triagem</Button></Box>
                    </Box>
                  </Form>
                </Paper>
              </Grid>
              <Grid item xs={9}>
                <Paper>
                  <Box display="flex">
                    <Box component="div" flexGrow={1} p={2}>
                      <Typography variant="h6" display="inline">Anexos</Typography>
                    </Box>
                  </Box>
                  <Box component="div" p={2} justifyContent="center">
                    {data.attachments.length === 0 && <Typography variant="body2">Sem anexos para exibir</Typography>}
                    {data.attachments.length > 0 && (
                      <Grid container spacing={2}>
                        {data.attachments.map((attachment: IAttachment) => (
                          <Grid key={attachment.id} xs={3} item>
                            <Paper variant="outlined" square style={{ cursor: attachment.status === 'DONE' ? 'pointer' : 'auto' }}>
                              <Box p={2} component="div" onClick={() => attachment.status === 'DONE' && handleDownloadAttachment(attachment.id, attachment.name)}>
                                <Typography noWrap display="block" variant="body2">{attachment.name}</Typography>
                                <Typography noWrap display="block" variant="caption">{attachment.size} bytes</Typography>
                                {attachment.is_final_report && (
                                  <Box color={theme.palette.info.dark}>
                                    <Typography noWrap display="block" variant="caption">Relatório Final</Typography>
                                  </Box>
                                )}
                                <Box display="inline-flex">
                                  <Box color={statusAttachmentHook[attachment.status].color} width={1}>
                                    <Typography noWrap display="block" variant="caption">{statusAttachmentHook[attachment.status].text}</Typography>
                                  </Box>
                                  {attachment.status === 'DONE' && (
                                    <Box color={statusAttachmentHook[attachment.status].color} width='10%'>
                                      <MdCloudDownload />
                                    </Box>)}
                                </Box>
                              </Box>
                            </Paper>
                          </Grid>
                        ))}

                      </Grid>)}
                  </Box>
                </Paper>
              </Grid>
              {data.answers.map((answer: IAnswer) => (
                <Grid key={answer.id} item xs={12}>
                  <Paper>
                    <Box component="div" p={2}>
                      <Typography variant="h6" display="inline">{answer.question}</Typography>
                    </Box>
                    <Box component="div" p={2}>
                      {decode(`${answer.answer}`)}
                    </Box>
                  </Paper>
                </Grid>))}
            </Grid>)}
        </Container>
      </Page>
    </OverPage>
  )
}

export default Show
